import React, { useEffect, useState, useCallback } from "react";

import { OverlayPanel } from "primereact/overlaypanel";

import Table from "components/Table/Table";
import Filters from "components/Filters/Filters";

import EditUser from "./ManageUser/EditUser";
import AddUser from "./ManageUser/AddUser";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import PopOver from "components/common/PopOver/PopOver";
import UserSearch from "./Search/UserSearch";
import QuitTask from "./QuitTask/QuitTask";
import ConvertUser from "./ConvertUser/ConvertUser";
import UserReport from "pages/Users/Report/UserReport";

import { USER_COLS } from "./UserTableData";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import {
  getUserList,
  getUserSearchList,
  getUserRoles,
  getLoggedInUser,
  deleteUser,
  onAddUser,
  onEditUser,
  setUserList,
  onSearchUsers,
  quitTask,
  convertUserRole,
  restoreSelectedUser,
  resetSelectedUser,
  getInspectionTask,
  getSalesOfficesOfUser,
  setInspectionTask,
} from "redux/pages/Administration/User/userSlice";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface IUser {
userRoleList?:any
}

export const Users: React.FC<IUser> = ({userRoleList=[]}) => {
  const userList = useAppSelector((state) => state.user.userList);
  const userRoles = useAppSelector((state) => state.user.userRoles);
  const userFilterRoles = useAppSelector((state) => state.user.userFilterRoles);
  const userPageDetails = useAppSelector((state) => state.user.userPageDetails);
  const loggedInUser = useAppSelector((state) => state.user.loggedInUser);
  const userSearchId = useAppSelector((state) => state.user.userSearchId);
  const userRoleIdOnSearch = useAppSelector( (state) => state.user.userRoleIdOnSearch);
  const inspectionTaskDetails = useAppSelector((state) => state.user.inspectionTask);
  const selectedUserRow = useAppSelector((state) => state.user.selectedUserRow);
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
  
  const [pageDetails, setPageDetails] = useState<any>({});
  const [filterExpanded, setFilterExpanded] = useState<any>(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userRole, setUserRole] = useState<any>();
  const [userRoleId, setUserRoleId] = useState<any>();
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [currentStart, setCurrentStart] = useState(1);
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [isRestore,setIsRestore] = useState(false);
  const [isDelete,setIsDelete]=useState(false);
  const [showUserSearchModal, setShowUserSearchModal] = useState(false);
  const [showQuitTaskModal, setShowQuitTaskModal] = useState(false);
  const [showConvertUserModal, setShowConvertUserModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [searchStatusId, setSearchStatusId] = useState(0);
  const [userColumns, setUserColumns] = useState<any>(USER_COLS)
  const [selectedSalesOffices, setSelectedSalesOffices] = useState<any>([]);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [userSearchAppliedFlag, setUserSearchAppliedFlag] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();

  const onUserRoleChange = (event: any) => {
    if(event !== userRole) {
    let columns = userColumns.filter((item) => item.field !== "email")
    setUserColumns([])
    setUserColumns(columns);
    dispatch(setUserList([]));
    }
    setSearchStatusId(0);
    setUserRole(event);
    setPageIndex(0);
    let userRoleNumber = 1;
    switch (event) {
      case "Super Administrators":
        userRoleNumber = 1;
        setUserColumns(USER_COLS)
        break;
      case "Support":
        userRoleNumber = 2;
        break;
      case "Admin":
        userRoleNumber = 3;
        break;
      case "Admin-Tech":
        userRoleNumber = 4;
        break;
      case "Tech":
        userRoleNumber = 5;
        break;
      case "Branch":
        userRoleNumber = 6;
        break;

      default:
        userRoleNumber = 1;
        break;
    }
    setUserRoleId(userRoleNumber);
  }

  useEffect(() => {
    if (userFilterRoles && userFilterRoles.length > 0) {
      setUserRoleId(userFilterRoles[0].id);
      setUserRole(userFilterRoles[0].name);
    }
  }, [userFilterRoles]);

  useEffect(() => {
    if (userList && userList.length) {
      setSelectedRows([userList[0]])
    }
  }, [userList]);

  useEffect(() => {
    if (userSearchId.length === 0) {
      if(userRoleId){
        let body = {
          userRoleId: userRoleId,
        };
        dispatch(getUserRoles());       
        dispatch(getUserList(body, 1, keyWord, sortField, sortOrder, searchStatusId));
        dispatch(getLoggedInUser());
      }
    } else {
      dispatch(getUserRoles());
      setUserRole(userRoleIdOnSearch);
      let userRoleId = 1;
      switch (userRoleIdOnSearch) {
        case "Super Administrators":
          userRoleId = 1;
          break;
        case "Support":
          userRoleId = 2;
          break;
        case "Admin":
          userRoleId = 3;
          break;
        case "Admin-Tech":
          userRoleId = 4;
          break;
        case "Tech":
          userRoleId = 5;
          break;
        case "Branch":
          userRoleId = 6;
          break;
        default:
          userRoleId = 1;
          break;
      }
      setUserRoleId(userRoleId);
      dispatch(getUserSearchList(1, userSearchId));
      dispatch(getLoggedInUser());
    }
  }, [dispatch, userRoleId, rowsPerPage]);

  useEffect(() => {
    if (userPageDetails) {
      setPageDetails(userPageDetails);
    }
  }, [userPageDetails])

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
      setPageDetails(modifiedPageDetails);
      setCurrentStart(1)
      setPageIndex(0)
    }
  }, [rowsPerPage])

  useEffect(() => {
    if (selectedUserRow) {
      setSelectedRows(selectedUserRow)
    }
  }, [selectedUserRow])

  const onPageChanged = (options: any) => {
    if (userRoleId && (options !== pageIndex)) {
      let body = {
        userRoleId: userRoleId,
      };
      let currentStart = options * pageDetails?.currentLength + 1;
      setCurrentStart(currentStart)
      setPageIndex(options);
      dispatch(getUserList(body, currentStart, keyWord, sortField, sortOrder, searchStatusId));
    }
  };

  const onGlobalSearch = (filter) => {
    let body = {
      userRoleId: userRoleId,
    };
    setKeyWord(filter);
    dispatch(getUserList(body, 1, filter, sortField, sortOrder, searchStatusId))
  }

  const onSort = (event) => {
    if (event && userList && userList.length) {
      let body = {
        userRoleId: userRoleId,
      };
      let sortDirection: string;
      if (event.sortOrder === 1)
        sortDirection = "ASC"
      else
        sortDirection = "DESC"
      setSortField(event.sortField);
      setSortOrder(sortDirection);
      dispatch(getUserList(body, currentStart, keyWord, event?.sortField, sortDirection, searchStatusId));
    }
  }

  useEffect(() => {
    if (userRoles) {
      let userRole: { name: string; code: string }[] = [];
      userRoles.map((item: any) => {
        return userRole.push({
          name: item.userRoleName,
          code: item.userRoleId,
        });
      });
    }
  }, [userRoles]);

  const handleRowSelect = useCallback((data: any) => {
    //To resolve useEffect dependency
    setSelectedRows([...data]);
  }, []);

  const deleteIconClick = () => {    
    if (selectedRows.length < 1) {
      setMessage("Select a User");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      switch (selectedRows[0].status) {
        case "Deleted":
          setMessage("User already deleted");
          setDisplayInfoModal(true);
          break;
        case "Expired":
          setMessage(
            "Selected user account has been expired and cannot be deleted"
          );
          setDisplayInfoModal(true);
          break;
        case "Not Activated":
          setMessage("Selected user is not activated and cannot be deleted");
          setDisplayInfoModal(true);
          break;
        case "Locked":
          setMessage("Locked user cannot be deleted");
          setDisplayInfoModal(true);
          break;
        case "Active":
        case "Blocked":
          if (loggedInUser?.uniqueUserId === selectedRows[0].uniqueUserId) {
            setMessage("Logged-in user cannot be deleted");
            setDisplayInfoModal(true);
          } else {
            setMessage("Are you sure you want to delete the selected User?");
            setDisplayConfirmModal(true);
            setIsDelete(true);
          }
          break;
        default:
          break;
      }
    } else if (selectedRows.length > 1) {
      const userStatus = selectedRows.filter((obj) => {
        return obj.status === "Active" || obj.status === "Blocked";
      });
      if (
        selectedRows.findIndex((obj) => {
          return obj.uniqueUserId === loggedInUser.uniqueUserId;
        }) !== -1
      ) {
        setMessage("One of the selected User is Logged-in user, cannot be deleted");
        setDisplayInfoModal(true);
      } else if (userStatus.length === 0) {
        setMessage("None of the selected users is in Active/Blocked Status, cannot be deleted");
        setDisplayInfoModal(true);
      } else if (userStatus.length === selectedRows.length) {
        setMessage("Are you sure you want to delete the selected Users?");
        setDisplayConfirmModal(true);
        setIsDelete(true);
      } else if (userStatus.length !== selectedRows.length) {
        setMessage("Some of the selected Users are in Locked/Not Activated status. Do you want to continue?");
        setDisplayConfirmModal(true);
        setIsDelete(true);
      }
    }
  };

  const onConfirmClick = () => {
    if (isRestore) {
      restoreUser();
    } else if (isDelete) {
      removeUser();
    }
  }

  const onCancelClick=()=>{
    setIsDelete(false)
    setIsRestore(false);
  }
  const removeUser = () => {
    setDisplayConfirmModal(false);
    let usersToDelete: string[] = [];
    selectedRows.map((item: any) => {
      if (item.status === "Active" || item.status === "Blocked") {
        usersToDelete.push(item.uniqueUserId);
      }
      return usersToDelete;
    });    
      dispatch(
        deleteUser(
          usersToDelete,
          setMessage,
          setDisplayInfoModal,
          selectedRows
        )
      );
      setIsDelete(false);
  };
  const restoreUser=()=>{
    setDisplayConfirmModal(false);
    dispatch(
        restoreSelectedUser(
          selectedRows[0].uniqueUserId,
          setMessage,
          setDisplayInfoModal,
          selectedRows[0]
        )
      );
      setIsRestore(false);
  }
  const onAddUserClick = (submitData: any) => {
    dispatch(
      onAddUser(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowAddModal,
        setSelectedRows
      )
    );
  };

  const onSearchClick = (submitData: any) => {
    setUserSearchAppliedFlag(true);
    setKeyWord('');
    dispatch(
      onSearchUsers(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowUserSearchModal,
        setUserSearchAppliedFlag
      )
    );
  };

  const onEditIconClick = () => { 
    if (selectedRows.length < 1) {
      setMessage("Select a User");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else {
      switch (selectedRows[0].status) {
        case "Deleted":
          setMessage("Deleted user cannot be edited");
          setDisplayInfoModal(true);
          break;
        case "Expired":
        case "Not Activated":
        case "Locked":
        case "Active":
        case "Blocked":
          if (loggedInUser?.uniqueUserId === selectedRows[0].uniqueUserId) {
            setMessage("Logged-in user cannot be edited");
            setDisplayInfoModal(true);
          } else {
            setShowEditModal(true);
          }
          break;
        default:
          break;
      }
    }
  };

  const onEditUserClick = (submitData: any) => {
    let uniqueUserId: any;
    if (submitData.uniqueUserId) uniqueUserId = submitData.uniqueUserId;
    else uniqueUserId = selectedRows[0].uniqueUserId;
    dispatch(
      onEditUser(
        submitData,
        uniqueUserId,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal,
        selectedRows[0]
      )
    );
  };

  const onSaveRowEdit = (submitData: any) => {
    let uniqueUserId: any;
    uniqueUserId = submitData.uniqueUserId;
    let offices = getSalesOfficeIds(selectedSalesOffices)
    submitData.salesOffices = offices;
    submitData.loginName=submitData.loginName?.trim();
    submitData.firstName= submitData.firstName?.trim();
    submitData.middleName=submitData.middleName?.trim();
    submitData.lastName= submitData.lastName?.trim();
    dispatch(
      onEditUser(
        submitData,
        uniqueUserId,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal,
        selectedRows[0]
      )
    );
  };

  const getSalesOfficeIds = (salesOfficeIds: any) => {
    let officeIds: any = [];
    salesOfficeIds.map((item: any) => {
        officeIds.push(item.uniqueSalesOfficeId);
        return null;
    });
    return officeIds;
}

  const onRowEditValidation = (rowData: any) => {
    let value = true;

    if (
      !rowData.userName ||
      !rowData.status ||
      !rowData.accountActivationDate ||
      !rowData.accountExpiryDate ||
      !rowData.email ||
      !isEmailValid(rowData.email)
    ) {
      value = false;
    }
    if (
      new Date(rowData.accountActivationDate) >
      new Date(rowData.accountExpiryDate)
    ) {
      setDisplayInfoModal(true);
      setMessage(
        "'Account Activates on' must be earlier than 'Account Expires on'"
      );
      value = false;
    }
    if (new Date(rowData.accountExpiryDate) <= new Date()) {
      setDisplayInfoModal(true);
      setMessage("Account Expiry Date must be a future date");
      value = false;
    }
    return value;
  };

  const isEmailValid = (email) => {
    if (!email) {
      return true;
    }
    return /^\S+@\S+\.\S+$/.test(email);
  };

  const onRowEditClick = (rowData) => {
    setSelectedRows([rowData])
    let isEditable = true;
    switch (rowData.status) {
      case "Deleted":
        setMessage("Deleted user cannot be edited");
        setDisplayInfoModal(true);
        isEditable = false;
        break;
      case "Expired":
      case "Not Activated":
      case "Locked":
      case "Active":
      case "Blocked":
        if (loggedInUser?.uniqueUserId === rowData.uniqueUserId) {
          setMessage("Logged-in user cannot be edited");
          setDisplayInfoModal(true);
          isEditable = false;
        } else {
          dispatch(getSalesOfficesOfUser(selectedRows[0]?.uniqueUserId, setSelectedSalesOffices));
          isEditable = true;
        }
        break;
      default:
        break;
    }
    return isEditable;
  };
  
  const onResetUserClick=((selectedRows)=>{
    if (selectedRows.length < 1) {
      setMessage("Select a User");
      setDisplayInfoModal(true);
    }
    else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
    else if(selectedRows[0].status!=="Active")
    {
      setMessage("Selected User is not Active, Password cannot be reset");
      setDisplayInfoModal(true);
    }
    else
    {
      dispatch(
        resetSelectedUser(
          selectedRows[0].uniqueUserId,
          setMessage,
          setDisplayInfoModal
        )
      );
    }
  });

  const onRestoreUserClick=((selectedRows)=>{
    if (selectedRows.length < 1) {
      setMessage("Select a User");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if(selectedRows[0].status==="Not Activated") {
      setMessage("Selected user is not activated and cannot be restored");
      setDisplayInfoModal(true);
    } else if(selectedRows[0].status==="Active") {
      setMessage("Selected user is active");
      setDisplayInfoModal(true);
    } else if(selectedRows[0].status==="Expired") {
      setMessage("Selected user account has expired and cannot be restored");
      setDisplayInfoModal(true);
    } else if(selectedRows[0].status==="Locked") {
      setMessage("Selected user is locked and cannot be restored");
      setDisplayInfoModal(true);
    } else if(selectedRows[0].status==="Blocked") {
      setMessage("Selected user is Blocked, cannot be restored");
      setDisplayInfoModal(true);
    } else{
      setMessage("Are you sure you want to restore the selected User?")
      setDisplayConfirmModal(true);
      setIsRestore(true);
    }
  })

  const getSettingsItem = useCallback((selectedRows) => {
    if (userList && userList.length) {
      switch (userRoleId) {
        case 1: {
          return [
            {
              id: 1,
              name: "Restore",
              onClick: ()=>{onRestoreUserClick(selectedRows)},
            },
            {
              id: 2,
              name: "Reset Password",
              onClick: () => { onResetUserClick(selectedRows)},
            },
            {
              id: 3,
              name: "Search",
              onClick: () => { setShowUserSearchModal(true) },
            },
            {
              id: 4,
              name: "Quit Task",
              onClick: () => { quitTaskHandler(selectedRows); },
            },
          ]
        }
        case 2:
        case 4:
        case 5: {
          return [
            {
              id: 1,
              name: "Restore",
              onClick: ()=>{onRestoreUserClick(selectedRows)},
            },
            {
              id: 2,
              name: "Reset Password",
              onClick: () => { onResetUserClick(selectedRows)},
            },
            {
              id: 3,
              name: "Search",
              onClick: () => { setShowUserSearchModal(true) },
            },
            {
              id: 4,
              name: "Quit Task",
              onClick: () => { quitTaskHandler(selectedRows) },
            },
            {
              id: 5,
              name: "Convert to other User Role",
              onClick: () => { convertUserHandler(selectedRows) }
            },
          ];
        }
        case 3:
        case 6:{
          return [
            {
              id: 1,
              name: "Restore",
              onClick: ()=>{onRestoreUserClick(selectedRows)},
            },
            {
              id: 2,
              name: "Reset Password",
              onClick: () => { onResetUserClick(selectedRows)},
            },
            {
              id: 3,
              name: "Search",
              onClick: () => { setShowUserSearchModal(true) },
            },
            {
              id: 5,
              name: "Convert to other User Role",
              onClick: () => { convertUserHandler(selectedRows) }
            },
          ];
        }
      }
    } else {
      return [
        {
          id: 1,
          name: "Search",
          onClick: () => { setShowUserSearchModal(true) },
        },
      ]
    }
  }, [userRoleId, userList]);

  const quitTaskHandler = (rowSelected) => {
    if (rowSelected && rowSelected.length) {
      if (rowSelected.length === 1) {
        dispatch(getInspectionTask(rowSelected[0]?.uniqueUserId, setMessage, setDisplayInfoModal));
      } else {
        setMessage("This operation can be performed only one row at a time");
        setDisplayInfoModal(true);
      }
    } else {
      setMessage("Select a user");
      setDisplayInfoModal(true);
    }
  }

  const convertUserHandler = (rowSelected) => {
    if (rowSelected && rowSelected.length) {
      if (rowSelected.length === 1) {
        let selectedUser = rowSelected[0];
        if (selectedUser.userRole === "Super Administrator") {
          setMessage("Super Administrator can not be converted into any other Role.");
          setDisplayInfoModal(true);
        } else if (selectedUser.status && selectedUser.status === "Active") {
          setShowConvertUserModal(true);
        } else {
          setMessage("User not in Active Status");
          setDisplayInfoModal(true);
        }
      } else {
        setMessage("This operation can be performed only one row at a time");
        setDisplayInfoModal(true);
      }
    }
  }

  useEffect(() => {
    if (inspectionTaskDetails && inspectionTaskDetails?.inspectionTaskId) {
      setShowQuitTaskModal(true);
    }
  }, [inspectionTaskDetails])

  useEffect(() => {
    if (!showQuitTaskModal) {
      dispatch(setInspectionTask(""))
    }
  }, [showQuitTaskModal])

  const onQuitTask = (submitData: any) => {
    dispatch(
      quitTask(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowQuitTaskModal
      )
    )
  };

  const onConvertRole = (selectedUser: any, submitData: any) => {
    dispatch(
      convertUserRole(
        selectedUser,
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowConvertUserModal
      )
    )
  }

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const getSelectedUsersId = () => {
    let params: any = [];
    selectedRows.map(item => {
        params.push(item.uniqueUserId);
    });
    return params;
};

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        userRoleId: userRoleId,
        search: keyWord,
        uniqueUserIds: "",
        sortColumn: sortField,
        sortOrder: sortOrder,
        StatusId: searchStatusId
      },
      exportType: "USERS"
    };
    dispatch(onExportReport(reportParameters, "Users"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        userRoleId: userRoleId,
        search: keyWord,
        sortColumn: sortField,
        sortOrder: sortOrder,
        StatusId: searchStatusId,
        uniqueUserIds: getSelectedUsersId()
      },
      exportType: "USERS"
    };
    dispatch(onExportReport(reportParameters, "Users"));
  };

  return (
    <div className="users-container d-flex">
      <section
        className={`${
          filterExpanded ? "expanded" : ""
        } user-filter d-flex flex-column align-items-center`}
      >
        <Filters
          filterExpanded={filterExpanded}
          setFilterExpanded={setFilterExpanded}
          userFilter
          onUserRoleChange={onUserRoleChange}
        />
      </section>
        <section className={`${filterExpanded ? "expanded" : ""} user-table`}>
          {userColumns.length > 0 && (<Table
            rows={userList}
            cols={userColumns}
            paginator
            dataKeyId="uniqueUserId"
            title={userRole}
            searchText="Enter User Name / Login Name"
            pageDetails={pageDetails}
            pageIndex={pageIndex}
            onAddClick={() => setShowAddModal(true)}
            onEditClick={onEditIconClick}
            onPageChanged={onPageChanged}
            getSelectedRowData={handleRowSelect}
            onDeleteClick={deleteIconClick}
            onSaveRowEdit={onSaveRowEdit}
            onRowEditValidation={onRowEditValidation}
            onRowEditClick={onRowEditClick}
            onGlobalSearch={onGlobalSearch}
            onSortData={onSort}
            settingListItems={getSettingsItem(selectedRows)}
            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
            defaultSelectedRows={selectedRows}
            dataFromUserTable={true}
            onUserSearchApplied={userSearchAppliedFlag}
          />)}
        </section>

        <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
        </ul>
      </PopOver>

      {showEditModal ? (
        <EditUser
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          userRoleId={userRoleId}
          selectedRows={selectedRows}
          onEditUserClick={(data) => onEditUserClick(data)}
        />
      ) : null}
      {showAddModal ? (
        <AddUser
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          userRoleId={userRoleId}
          onAddUserClick={(data) => onAddUserClick(data)}
        />
      ) : null}
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={onConfirmClick}
        cancelAction={onCancelClick}
        message={message}
      />
      {showUserSearchModal ? (
        <UserSearch
          showModal={showUserSearchModal}
          setShowModal={setShowUserSearchModal}
          userRoleId={userRoleId}
          searchId={searchStatusId}
          setStatusId={setSearchStatusId}
          onSearchClick={(data) => onSearchClick(data)}
          setUserSearchAppliedFlag={setUserSearchAppliedFlag}
          />
      ) : null}
      {showQuitTaskModal ? (
        <QuitTask
          showModal={showQuitTaskModal}
          setShowModal={setShowQuitTaskModal}
          selectedRows={selectedRows}
          inspectionTask={inspectionTaskDetails}
          onQuitTask={(data) => onQuitTask(data)}
         />
      ) : null}
      {showConvertUserModal ? (
        <ConvertUser
          showModal={showConvertUserModal}
          setShowModal={setShowConvertUserModal}
          selectedRows={selectedRows}
          onConvertRole={(data, body) => onConvertRole(data, body)}
        />
      ) : null}
      {showReportModal ? (
        <UserReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          userRoleId={userRoleId}
          selectedRows={selectedRows}
          sortByColumns={USER_COLS}
          searchKeyWord={keyWord}
          statusId={searchStatusId} />
      ) : null}
    </div>
  );
};

export default Users;
