import React, { useState, useEffect, memo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { FilterMatchMode } from "primereact/api";
import { InputNumber } from "primereact/inputnumber";

import PopOver from "components/common/PopOver/PopOver";
import DisplaySetting from "components/common/DisplaySetting/DisplaySetting";

interface ColProp {
    field: string;
    header: string;
    editor?: any;
    style?: any;
    sortable: boolean;
}

type Cols = ColProp[];

type Rows = any[];

type SettingListItem = {
    id: string | number;
    name: string;
    onClick: () => void;
};

interface IDataTableComponent {
    rows: Rows;
    cols: Cols;
    title: string;
    paginator?: boolean;
    dataKeyId?: string;
    pageDetails?: any;
    pageIndex?: number;
    getSelectedRowData?: (data: any) => void;
    onPageChanged?: (data: any) => void;
    searchboxfield?: boolean;
    showCustomSearchCmp?: boolean;
    CustomSearchCmp?: any;
    showFilterIcon?: boolean;
    onFilterClick?: () => void;
    showOptionsIcon?: boolean;
    onOptionsClick?: () => void;
    showCopyIcon?: boolean;
    onCopyClick?: () => void;
    onHeaderDropdownChange?: (value: any) => void;
    headerTemplate?: JSX.Element;
    showCustomHeader?: boolean;
    CustomHeaderCmp?: any;
    onSortData?: (value: any) => void;
    settingRef?: any
    onSaveRowEdit?: (data: any, index: any) => void;
    onRowEditValidation?: (data: any) => boolean;
    onRowEditClick?: (data: any, isData: boolean) => boolean;
    showroweditor?: boolean;
    settingListItems?: Array<SettingListItem>;
    defaultSelectedRows?: any;
    showCheckbox?: boolean;
    isLazyLoading?: boolean;
    selectedParentRows?: any;
    isSelectionFromParent?: boolean;
    isTemplateMode?: boolean;
    isTemplateFromParent?: boolean;
    templateFromParent?: (rowData: any, options: any) => JSX.Element;
    showExpandedRows?: boolean;
    rowExpandedTemplate?: (rowData: any, options: any) => JSX.Element;
}

export const DataTableComponent: React.FC<IDataTableComponent> = (props) => {
    const {
        rows = [],
        cols = [],
        title = "",
        paginator = false,
        dataKeyId = "",
        pageDetails = {},
        pageIndex = 1,
        getSelectedRowData = () => { },
        onPageChanged = () => { },
        showCustomHeader = false,
        CustomHeaderCmp = null,
        onSortData = () => { },
        settingRef = () => { },
        onSaveRowEdit = () => { },
        onRowEditValidation = () => true,
        onRowEditClick = () => true,
        showroweditor = false,
        settingListItems = [],
        defaultSelectedRows = [],
        showCheckbox = false,
        isLazyLoading = true,
        selectedParentRows = [],
        isSelectionFromParent = false,
        isTemplateMode = false,
        isTemplateFromParent = false,
        templateFromParent,
        showExpandedRows= false,
        rowExpandedTemplate
    } = props;

    const [rowData, setRowData] = useState<Array<any>>([]);
    const [tableColumns, setTableColumns] = useState<Array<any>>(cols);
    const [totalRows, setTotalRows] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortField, setSortField] = useState<any>();
    const [sortOrder, setSortOrder] = useState(null);
    const [firstPage, setFirstPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [showDisplaySetting, setShowDisplaySetting] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<any>({});
    const [editingRows, setEditingRows] = useState({});

    useEffect(() => {
        if (cols) {
            setTableColumns(cols);
        }
    }, [cols]);

    useEffect(() => {
        if (isSelectionFromParent) {
            setSelectedRows(selectedParentRows);
        }
    }, [selectedParentRows, isSelectionFromParent])

    useEffect(() => {
        setRowData(rows);
        setPageInputTooltip("Press 'Enter' key to go to this page.");
        setCurrentPage(pageIndex + 1);
        setFirstPage(pageIndex * pageDetails?.currentLength);
        let lastPageIndex = Math.ceil(pageDetails?.totalCount / pageDetails?.currentLength);
        setLastPage(lastPageIndex);
        setTotalRecords(pageDetails?.totalCount);
        setTotalRows(pageDetails?.pageCount);
    }, [pageDetails, rows, pageIndex]);

    const onCustomPage = (event) => {
        setFirstPage(event.first);
        setTotalRows(event.rows);
        setCurrentPage(event.page + 1);
        onPageChanged && onPageChanged(event.page);
    };

    const onSort = (event) => {
        setFirstPage(event.first);
        setTotalRows(event.rows);
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
        onSortData && onSortData(event);
    };

    const paginatorTemplate = {
        layout: "PrevPageLink NextPageLink FirstPageLink PageLinks LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageInput",
        FirstPageLink: (options) => {
            if (pageIndex > 2 && lastPage > 5) {
                return (
                    <button type="button" className={`${options.className} mr-3`} onClick={options.onClick} disabled={options.disabled}>
                        <span className="action-btn">1</span>
                        <Ripple />
                    </button>
                );
            }
        },
        PrevPageLink: (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="action-btn">Previous</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={`${options.className} mr-3`} onClick={options.onClick} disabled={options.disabled}>
                    <span className="action-btn">Next</span>
                    <Ripple />
                </button>
            );
        },
        LastPageLink: (options) => {
            if (pageIndex < lastPage - 3 && lastPage > 5) {
                return (
                    <button type="button" className={`${options.className} mr-3`} onClick={options.onClick} disabled={options.disabled}>
                        <span className="action-btn">{lastPage}</span>
                        <Ripple />
                    </button>
                );
            }
        },
        PageLinks: (options) => {
            if (
                (options.view.startPage === options.page && options.view.startPage !== 0) ||
                (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
            ) {
                const className = classNames(options.className, { "p-disabled": true });

                return (
                    <span className={`${className} mr-3`} style={{ userSelect: "none" }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        JumpToPageInput: (options) => {
            return null;
        },
        RowsPerPageDropdown: (options) => {
            return null;
        },
        CurrentPageReport: (options) => {
            return (
                <span className="current-page mr-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Go to{" "}
                    <InputNumber
                        size={2}
                        className="ml-1"
                        value={currentPage}
                        tooltip={pageInputTooltip}
                        tooltipOptions={{ position: "left" }}
                        onKeyDown={(e) => onPageInputKeyDown(e, options)}
                        onChange={onPageInputChange}
                    />
                </span>
            );
        },
    };

    const onPageInputKeyDown = (event, options) => {
        if (event.key === "Enter") {
            const page = parseInt(currentPage as any as string);
            if (page < 1 || page > options.totalPages) {
                setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
            } else {
                const first = currentPage ? options.rows * (page - 1) : 0;
                onPageChanged && onPageChanged(page - 1);
                setFirstPage(first);
                setPageInputTooltip("Press 'Enter' key to go to this page.");
            }
        }
    };

    const onPageInputChange = (event) => {
        setCurrentPage(event.value === null ? 0 : event.value);
    };
    const renderHeader = () => {
        return <>{props.headerTemplate}</>;
    };

    const searchHeader = renderHeader();

    useEffect(() => {
        if (defaultSelectedRows && defaultSelectedRows.length > 0) {          
            setSelectedRows(defaultSelectedRows);
            getSelectedRowData(defaultSelectedRows);
        } else {
            if (rowData?.length > 0 && !isSelectionFromParent) {
                if (selectedRows.length === 0) {
                    const firstRowEle = rowData[0];                 
                    setSelectedRows([firstRowEle]);
                    getSelectedRowData([firstRowEle]);
                }
            } else {
                setSelectedRows([]); //To clear previous selected row.
                getSelectedRowData([]); //To clear previous value of selected row.
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);

    const onSelectRow = (e: any) => {
        setSelectedRows(e.value);
        getSelectedRowData(e.value);
    };

    const onRowEditComplete = (e) => {
        let currentData = [...rowData];
        let { newData, index } = e;

        onSaveRowEdit && onSaveRowEdit(newData, index);
    };

    const rowEditValidator = (rowData, options) => {
        let value = false;
        value = onRowEditValidation(rowData);
        return value;
    };

    const onRowEditChange = (e) => {
        if (Object.keys(editingRows).length === 0 || Object.keys(e.data).length === 0) {
            let isEditable = true;
            const isData = Object.keys(e.data).length ? true : false; 
            const findIndex = rowData?.findIndex((item)=> item[dataKeyId] == Object.keys(e.data)[0]);         
            isEditable = onRowEditClick(rowData[findIndex],isData);
            if (isEditable) {
                setEditingRows(e.data);
            }
        }
    };    

    const columnTemplate = (rowData, options) => {
        return (
            <div>{rowData[options.field]}</div>
        )
    }

    const NormalColumns = tableColumns.map(({ field, header, editor, style, sortable, headerClassName, align }) => {
        return (
            <Column
                key={field}
                field={field}
                header={header}
                editor={editor}
                style={style}
                sortable={sortable}
                align={align}
                headerClassName={headerClassName}
            />
        )
    })

    const TemplateColumns = tableColumns.map(({ field, header, editor, style, sortable, headerClassName, align }) => {
        return (
            <Column
                key={field}
                field={field}
                header={header}
                editor={editor}
                style={style}
                sortable={sortable}
                align={align}
                headerClassName={headerClassName}
                body={isTemplateFromParent ? templateFromParent : columnTemplate}
            />
        )
    })

    const toggleRowExpansion = (e: any) => {
        let eventKeys = Object.keys(e.data);    
        if (eventKeys && eventKeys.length) {
            let item: any = {};
            if ((eventKeys.length === 1) || (!(Object.keys(expandedRows).length))) {
                item[`${eventKeys[0]}`] = true;
            } else {
                let rowsExpanded = Object.keys(expandedRows);
                eventKeys.forEach(element => {
                    if (!(rowsExpanded.includes(element.toString()))) {
                        item[`${element}`] = true;                  
                    }
                });
            }
            setExpandedRows(item);
        } else {
            setExpandedRows({});      
        }
    }

    return (
        <>
            <div className="custom-table bg-white">
                {showCustomHeader ? <CustomHeaderCmp /> : <div className="heading">{title}</div>}
                <DataTable
                    value={rowData}
                    size="small"
                    responsiveLayout="scroll"
                    editMode="row"
                    editingRows={editingRows}
                    onRowEditComplete={onRowEditComplete}
                    rowEditValidator={rowEditValidator}
                    onRowEditChange={onRowEditChange}
                    paginator={paginator}
                    paginatorTemplate={paginatorTemplate}
                    first={firstPage}
                    rows={totalRows}
                    onPage={onCustomPage}
                    onSort={onSort}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    selectionMode="multiple"
                    dragSelection
                    selection={selectedRows}
                    onSelectionChange={(e) => onSelectRow(e)}
                    dataKey={dataKeyId}
                    emptyMessage="No data found."
                    header={searchHeader}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters as any)}
                    scrollDirection="both"
                    scrollable
                    resizableColumns
                    columnResizeMode="expand"
                    showGridlines={false}
                    expandedRows={expandedRows}
                    totalRecords={totalRecords}
                    lazy={isLazyLoading}
                    onRowToggle={(e) => toggleRowExpansion(e)}
                    rowExpansionTemplate={rowExpandedTemplate}
                >
                    {showroweditor ? <Column rowEditor exportable={false} alignFrozen="left" frozen></Column> : null}
                    {showCheckbox ? (<Column selectionMode="multiple" headerStyle={{}} exportable={false}></Column>) : null}
                    {showExpandedRows ? (<Column expander={true} style={{ width: '5rem' }} />) : null}
                    {isTemplateMode ? TemplateColumns : NormalColumns}
                </DataTable>
            </div>
            <PopOver ref={settingRef}>
                <ul>
                    {settingListItems?.length > 0 &&
                        settingListItems.map((item: SettingListItem) => (
                            <li key={item?.id} role="button" onClick={item?.onClick}>
                                {item?.name}
                            </li>
                        ))}
                    <li role="button" onClick={() => setShowDisplaySetting(true)}>
                        Display Settings
                    </li>
                </ul>
            </PopOver>
            {showDisplaySetting && (<DisplaySetting
                showModal={showDisplaySetting}
                setShowModal={setShowDisplaySetting}
                displayColumns={cols}
                setdisplayColumns={setTableColumns}
                selectedCols={tableColumns}
            />)}
        </>
    );
};

export default memo(DataTableComponent);
