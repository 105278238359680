import { combineReducers } from '@reduxjs/toolkit';
import AdministrationSliceReducer from './pages/Administration/administrationSlice';
import LoginSliceReducer from './pages/Login/loginSlice';
import LogOutSliceReducer from './pages/Login/logOutSlice';
import DashboardSliceReducer from './pages/Dashboard/dashboardSlice';
import UserSliceReducer from './pages/Administration/User/userSlice';
import SiteSliceReducer from './pages/Administration/Site/siteSlice';
import ContractSliceReducer from './pages/Inspection/Contracts/contractSlice';
import SystemSliceReducer from './pages/Inspection/Systems/systemSlice';
import ReportSliceReducer from './pages/Manage/InspectionSettings/Reports/ReportsSlice';
import ReportTemplateSliceReducer from './pages/Manage/InspectionSettings/Reports/ReportTemplateSlice';
import ZoneSliceReducer from './pages/Manage/OrganizationSettings/zoneSlice';
import SalesOfficeSliceReducer from './pages/Manage/OrganizationSettings/salesOfficeSlice';
import BranchOfficeSliceReducer from './pages/Manage/OrganizationSettings/branchOfficeSlice';
import SearchSliceReducer from './pages/Administration/Search/searchSlice';
import InspectionTaskReducer from './pages/Inspection/InspectionTask/inspectionTaskSlice';
import InspectionTaskFilterReducer from './pages/Inspection/InspectionTask/inspectionTaskFilterSlice';
import HealthClassificationReducer from './pages/Manage/Lookups/HealthClassification/healthClassificationSlice';
import NFPAClassificationReducer from './pages/Manage/Lookups/NFPAClassification/NFPAClassificationSlice';
import ReportConfigSliceReducer from './pages/Manage/InspectionSettings/Reports/ReportConfigSlice';
import CommonAttributeSliceReducer from './pages/Manage/Customization/Attributes/CommonAttributeSlice';
import EquipmentTypeAttributeValueReducer from './pages/Manage/Customization/Attributes/EquipmentTypeAttributeValueSlice';
import EquipmentTypeAttributeSliceReducer from './pages/Manage/Customization/Attributes/EquipmentTypeAttributeSlice'
import ReportMappingReducer from './pages/Manage/InspectionSettings/ReportMapping/ReportMappingslice';
import InspectionFieldSlice from './pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice';
import InspectionReportSliceReducer from "./pages/Inspection/InspectionReports/inspectionReportsSlice"
import SelectSiteReducer from './pages/Dashboard/Header/selectSiteSlice'
import ReportGroupSliceReducer from './pages/Manage/InspectionSettings/Reports/ReportGroupSlice';
import EquipmentSliceReducer from './pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import personalSettingsSliceReducer from './pages/Dashboard/UserMenu/personalSettingsSlice';
import feedbackSliceReducer from './pages/Dashboard/UserMenu/feedbackSlice';
import sendMailSliceReducer from './pages/Dashboard/UserMenu/sendMailSlice';
import CommonSliceReducer from "./common/commonSlice";
import linkSliceReducer from './common/linkSlice';
import pluginSliceReducer from './common/pluginSlice';
import InspectionDetailsSlice from './pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice';
import passwordPolicySlice from 'pages/SecuritySettings/passwordPolicySlice';
import mailDomainSlice from './common/mailDomainSlice';
import logBookSlice from './pages/Administration/LogBook/logBookSlice';
import CustomizationSlice from './pages/Manage/Customization/CustomizationSlice';
import eventControllerSlice from './common/event/eventControllerSlice';
import ToolSlice from './pages/Manage/Tools/ToolSlice';
import BuildingSliceReducer from './pages/Administration/Building/buildingSlice';
import FloorSliceReducer from './pages/Administration/Floor/floorSlice';
import AsBuiltsSliceReducer from './pages/AsBuilts/AsBuiltsSlice';
import inspectionDrawingSliceReducer from './pages/Inspection/InspectionTask/inspectionDrawingSlice';
import CustomizeReportSliceReducer from './common/report/customizeReportSlice';
import ProvenOutcomesSliceReducer from './pages/Reports/ProvenOutcomesSlice';
import SalesActivitySliceReducer from './pages/Reports/SalesActivitySlice';

const rootReducer = combineReducers({
    administration: AdministrationSliceReducer,
    user: UserSliceReducer,
    site: SiteSliceReducer,
    login: LoginSliceReducer,
    logOut: LogOutSliceReducer,
    dashboard: DashboardSliceReducer,
    contract: ContractSliceReducer,
    system: SystemSliceReducer,
    report: ReportSliceReducer,
    reportTemplate: ReportTemplateSliceReducer,
    zone: ZoneSliceReducer,
    salesOffice: SalesOfficeSliceReducer,
    branchOffice: BranchOfficeSliceReducer,
    search: SearchSliceReducer,
    inspectionTask: InspectionTaskReducer,
    healthClassification: HealthClassificationReducer,
    nfpaclassification: NFPAClassificationReducer,
    reportConfig: ReportConfigSliceReducer,
    commonAttribute: CommonAttributeSliceReducer,
    equipmentTypeAttributeValue: EquipmentTypeAttributeValueReducer,
    inspectionTaskFilter: InspectionTaskFilterReducer,
    EquipmentTypeAttribute: EquipmentTypeAttributeSliceReducer,
    reportMapping: ReportMappingReducer,
    InspectionField: InspectionFieldSlice,
    inspectionReport: InspectionReportSliceReducer,
    selectSite: SelectSiteReducer,
    reportGroup: ReportGroupSliceReducer,
    equipment: EquipmentSliceReducer,
    personalSettings: personalSettingsSliceReducer,
    common: CommonSliceReducer,
    link: linkSliceReducer,
    plugin: pluginSliceReducer,
    inspectionDetails: InspectionDetailsSlice,
    passwordPolicy: passwordPolicySlice,
    mailDomain: mailDomainSlice,
    logBook: logBookSlice,
    customization: CustomizationSlice,
    feedback: feedbackSliceReducer,
    sendMail: sendMailSliceReducer,
    event: eventControllerSlice,
    tool: ToolSlice,
    building: BuildingSliceReducer,
    floor: FloorSliceReducer,
    asbuilts: AsBuiltsSliceReducer,
    inspectionDrawing: inspectionDrawingSliceReducer,
    customizeReport: CustomizeReportSliceReducer,
    provenOutcomes:ProvenOutcomesSliceReducer,
    salesActivity:SalesActivitySliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;