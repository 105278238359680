import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getTechnicians } from 'redux/pages/Inspection/InspectionTask/inspectionTaskSlice';
import { InputNumber } from 'primereact/inputnumber';

interface EquipmentGroupItem {
    name: string,
    code: string
};

interface EquipmentTypeItem {
    name: string,
    code: string
}


export const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

export const customerTextEditor = (options) => {
    return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

interface Props {
    getTableColumns?: (data: any) => void,
    selectedRow: any;
    customerAllChecked: boolean | undefined;
    contractAllChecked: boolean | undefined;
    contractNumber: any;

}

export const InspectionTableData: React.FC<Props> = ({
    getTableColumns = () => { },
    selectedRow = [],
    customerAllChecked = false,
    contractAllChecked = false,
    contractNumber = "",
}) => {
    const technicianData = useAppSelector((state) => state.inspectionTask.technicianData);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const [technicianLookup, setTechnicianLookup] = useState([])
    const [countryId, setCountryId] = useState<any>()
    const inspectionColumns = useRef<any>();
    const [showNotificationColumn, setShowNotificationColumn] = useState<boolean>();
    const dispatch = useDispatch<any>()

    useEffect(() => {
        if (selectedRow) {
            let body = {
                UniqueContractId: selectedRow?.uniqueContractId,
            }
            dispatch(getTechnicians(body));
        }
    }, [selectedRow])

    useEffect(() => {
        if (technicianData?.length > 0) {
            let technician: any = [];
            technicianData.map((item: any) => {
                return technician.push({ label: item.formattedLeadTechnicianName, value: item.uniqueUserId })
            })
            setTechnicianLookup(technician)
        }
    }, [technicianData])

    useEffect(() => {
        if (loggedInUserDetails) {
            setCountryId(loggedInUserDetails?.userDefaults?.countryId);
        }
    }, [loggedInUserDetails])

    useEffect(() => {
        if (contractNumber.length > 0) {
            if (countryId === 220 && contractNumber.startsWith("44OP") ||
                countryId === 220 && contractNumber.startsWith("44OC") ||
                countryId === 38 && contractNumber.startsWith("60") ||
                countryId === 38 && /^[a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4}$/.test(contractNumber) ||
                countryId === 38 && /^[a-zA-Z][-]SI[-][0-9]{5}$/.test(contractNumber) ||
                countryId === 38 && contractNumber.startsWith("13")) {
                setShowNotificationColumn(false)
            } else {
                setShowNotificationColumn(true);
            }
        }
    }, [contractNumber])

    const getErrorMsg = options => {
        const { field, value } = options;
        const FIELD_REQUIRED = 'This field is required';
        const NUMBER_VALID = 'Enter a valid Notification Number';
        switch (field) {
            case 'inspectionTaskName': {
                if (!value?.trim())
                    return FIELD_REQUIRED;
                break;
            }
            case 'qtyOfTech': {
                if (!value)
                    return FIELD_REQUIRED;
                break;
            }
            case 'notificationNumber': {
                if (options)
                    if (!isPatternValid(value))
                        return NUMBER_VALID
                    else if (!value)
                        return FIELD_REQUIRED
                break;
            }
            default: {
                return ''
            }
        }
    };

    const isPatternValid = (notificationNumber) => {
        if (!notificationNumber) {
            return true;
        }
        if (countryId === 38) {
            return /^((600)[0-9]{6}|[5][0-9]{9})$/.test(notificationNumber);
        } else if (countryId === 220) {
            return /^[5][0-9]{9}$/.test(notificationNumber);
        }
    }

    const inspectionTextEditor = (options) => {
        return <InputText disabled type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    
    const inspectionSystemTextEditor = (options) => {
        return <InputText disabled type="text" value={options?.rowData?.systemNames} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const inspectionNameEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const qtyofTechEditor = (options) => {
        const errorMsg = getErrorMsg(options);
        const handleFocus = (event) => {
            event.target.setAttribute('autocomplete', 'off');
          };
        return <>
            <InputNumber className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} inputId="inputnumber" type="text" value={options.value} onChange={(e) => options.editorCallback(e.value)} onFocus={handleFocus} />
            {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
        </>
    };

    const inspectiontaskEditor = (options) => {
        let errorMsg: any = "";
        let isNotificationNumberDisabled = false
        if (countryId === 220 && options.rowData.contractNumber.startsWith("44OP") ||
            countryId === 220 && contractNumber.startsWith("44OC") ||
            countryId === 38 && options.rowData.contractNumber.startsWith("60") ||
            countryId === 38 && options.rowData.contractNumber.startsWith("A") ||
            countryId === 38 && options.rowData.contractNumber.startsWith("A-SI") ||
            countryId === 38 && options.rowData.contractNumber.startsWith("13")) {
            isNotificationNumberDisabled = true
        } else {
            errorMsg = getErrorMsg(options);
        }
        return <>
            <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} disabled={isNotificationNumberDisabled} type="text" value={options.value === null ? "" : options.value} onChange={(e) => options.editorCallback(e.target.value)} />
            {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
        </>
    };

    const dateEditor = (options) => {
        let date = new Date(options.value)
        let timezoneoffset = date.getTimezoneOffset();
        let localDate = new Date();
        if(timezoneoffset >=0)
        localDate = new Date(date.getTime() + (timezoneoffset) * 60000)
        else
        localDate = new Date(date.getTime() - (timezoneoffset) * 60000)
        return <Calendar id="calendar" dateFormat="yy-mm-dd" value={localDate} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    const leadTechnicianEditor = (options) => {
        return (
            <Dropdown value={options.value} optionLabel="label" optionValue='label'
                options={technicianLookup}
                onChange={(e) => options.editorCallback(e.value)}
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }

    const INSPECTION_TASKS_COLS = [
        {
            field: 'inspectionTaskName',
            header: 'Inspection Task Name',
            editor: (options) => inspectionNameEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'systemName',
            header: 'System',
            editor: (options) => inspectionSystemTextEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'panelType',
            header: 'Panel Type',
            editor: (options) => inspectionTextEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'plannedStartDate',
            header: 'Planned Start Date',
            editor: (options) => dateEditor(options),
            style: { flex: '1 0 180px' },
            sortable: true,
        },
        {
            field: 'plannedEndDate',
            header: 'Planned End Date',
            editor: (options) => dateEditor(options),
            style: { flex: '1 0 180px' },
            sortable: true,
        },
        {
            field: 'status',
            header: 'Status',
            editor: (options) => inspectionTextEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
        },
        {
            field: 'statusUpdateDate',
            header: 'Status Updated',
            style: { flex: '1 0 130px' },
            sortable: true,
        },
        {
            field: 'leadTechnician',
            header: 'Lead Technician',
            editor: (options) => leadTechnicianEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'qtyOfTech',
            header: 'Qty of Techs',
            editor: (options) => qtyofTechEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
            align: "left",
        },
        {
            field: 'notificationNumber',
            header: 'Notification Number',
            editor: (options) => inspectiontaskEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
            
        },
        {
            field: 'equipmentTestedPercentage',
            header: 'Equipment Tested in %',
            style: { flex: '1 0 200px' },
            sortable: true,
            align: "left",
        }
    ];

    const INSPECTION_TASKS_OPTIONAL_COLS = [
        {
            field: 'notificationNumber',
            header: 'Notification Number',
            editor: (options) => inspectiontaskEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ];

    useEffect(() => {
        if (showNotificationColumn === true || customerAllChecked === true || contractAllChecked === true) {
            inspectionColumns.current = INSPECTION_TASKS_COLS;
        } else {
            let newcols = INSPECTION_TASKS_COLS.filter(cols => cols.field !== INSPECTION_TASKS_OPTIONAL_COLS[0].field)
            inspectionColumns.current = newcols;
        }
        getTableColumns(inspectionColumns.current);
    }, [getTableColumns, technicianLookup, showNotificationColumn, customerAllChecked, contractAllChecked]);

    return (
        <></>
    )

}

export default InspectionTableData;

export const INSPECTION_TASKS_Extra_COLS = [

    {
        field: 'customerName',
        header: 'Customer',
        editor: (options) => customerTextEditor(options),
        style: { flex: '1 0 200px' },
        sortable: true,
    },
    {
        field: 'contractName',
        header: 'Contract',
        editor: (options) => customerTextEditor(options),
        style: { flex: '1 0 200px' },
        sortable: true,
    }
]

export const EQUIPMENT_LIST_COLS = [
    {
        field: 'equipmentType',
        header: 'Equipment Type',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 200px' },
        sortable: true,
    },
    // {
    //     field: 'barcode',
    //     header: 'Barcode',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'address',
    //     header: 'Address',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'nfpa',
    //     header: 'NFPA Classification',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'xmlType',
    //     header: 'XML Type',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'xmlUsage',
    //     header: 'XML Usage',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'location',
    //     header: 'Location',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'health',
    //     header: 'Health Classification',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'testMethod',
    //     header: 'Test Method',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
    // {
    //     field: 'testResult',
    //     header: 'Test Result',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 200px' },
    //     sortable: true,
    // },
];

export const EQUIPMENT_LIST_ROWS = [
    {
        uniqueInspectionTaskId: 1,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 2,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 3,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 4,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 5,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 6,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 7,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 8,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 9,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 10,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 11,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 12,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 13,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 14,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 15,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 16,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 17,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 18,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 19,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    },
    {
        uniqueInspectionTaskId: 20,
        equipmentType: 'Devices',
        barcode: '',
        address: '01:002-001',
        nfpa: 'System Smoke Detector (P)',
        xmlType: 'HFP-11',
        xmlUsage: 'Alarm',
        location: 'HFP-11 @ address 2-1',
        health: 'Smoke Detector',
        testMethod: 'None',
        testResult: 'Not Tested'
    }
];


export const DEFAULT_HEADER_LIST = [
    {
        id: 1,
        name: 'Contract',
        selected: false
    },
    {
        id: 2,
        name: 'System',
        selected: false
    },
    {
        id: 3,
        name: 'Questions',
        selected: false
    },
    {
        id: 4,
        name: 'Equipment',
        selected: true
    },
    {
        id: 5,
        name: 'Summary',
        selected: false
    }
];

export const DEFAULT_EQUIPMENT_GROUP_LIST: Array<EquipmentGroupItem> = [
    { name: 'All', code: 'All' },
    { name: `01 Node @ address 1`, code: 'N1' },
    { name: `02 Node @ address 2`, code: 'N2' }
];

export const DEFAULT_EQUIPMENT_TYPE_LIST: Array<EquipmentTypeItem> = [
    { name: 'All', code: 'All' },
    { name: `Devices`, code: 'DV' },
    { name: `Doors`, code: 'DR' },
    { name: `Elevators`, code: 'EL' }
];