import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from 'primereact/progressspinner';
import { BsExclamationCircleFill } from "react-icons/bs";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import InspectionDetails from "./../InspectionTasks/ManageInspectionTasks/InspectionDetails/InspectionDetails";
import InspectionReports from "./../InspectionTasks/ManageInspectionTasks/InspectionReports";

//redux
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { clearGeneratedReportDetails, clearSystemSummary, getSystemList, getSystemSummary, onGenerateReport, setDefaultSystemDetails, setSystemList } from "../../redux/pages/Inspection/InspectionReports/inspectionReportsSlice";
import { setContractUpdated } from "redux/pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice";
import {fetchLoggedInUserDetails, setLoggedInUserDetails} from "../../redux/pages/Administration/administrationSlice";
import { getSelectedReportList, onUpdateReportSelected } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";

//service
import { showUI } from "service/GeneralUtility";
import { setProgressFlag } from "redux/common/commonSlice";

interface IGenerateReports {
    toggleGenerateClick:boolean;
    setToggleGenerateClick:(state: boolean) => void;
}

type FormData = {
    uniqueSystemId: string;
    output: boolean;
    reportBy: number;
    include: boolean;
    taskFilter: number;
    equipmentGroup: any;
    systems: any;
    buildings: any;
    inspectionTask: any;
    inspectionReport: any;
    equipmentTestStartDate: Date;
    equipmentTestEndDate: Date;
    SendLog: boolean;
};

const GenerateReports: React.FC<IGenerateReports> = ({
    toggleGenerateClick = false,
    setToggleGenerateClick = () => {}
}) => {
    const dispatch = useDispatch<any>();
    const generateReportForm: any = useRef();

    const systemsList = useAppSelector((state) => state.inspectionReport.systemList);
    const systemSummary = useAppSelector((state) => state.inspectionReport.systemSummary);
    const generatedReportDetais = useAppSelector((state) => state.inspectionReport.generatedReportDetails);
    const defaultSystemDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const contractUpdated = useAppSelector((state) => state.inspectionDetails.contractUpdated);
    const reportList = useAppSelector((state) => state.inspectionTask.selectedReportList);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

    const [systemLookup, setSystemLookup] = useState([{ name: "", id: "" }]);
    const [selectedSystemId, setSelectedSystemId] = useState<any>(null);
    const [reportBy, setReportBy] = useState({name: "Equipment Group", value: 1});
    const [inspectionTaskDetails, setInspectionTaskDetails] = useState<any>([]);
    const [inspectionReportDetails, setInspectionReportDetails] = useState<any>([]);
    const [buildingDetails, setBuildingDetails] = useState<any>([]);
    const [panelDetails, setPanelDetails] = useState<any>([]);
    const [systemDetailsLoader, setSystemDetailsLoader] = useState<boolean>(false);
    const [selectedEquipmentGroups, setselectedEquipmentGroups] = useState<any>([]);
    const [selectedBuildings, setSelectedBuildings] = useState<any>([]);
    const [selectedSystems, setSelectedSystems] = useState<any>([]);
    const [selectedInspectionTasks, setSelectedInspectionTasks] = useState<any>([]);
    const [selectedReports, setSelectedReports] = useState<any>([]);
    const [selectedTaskHistoryFilter, setSelectedTaskHistoryFilter] = useState<any>(6);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showEquipmentListModal, setShowEquipmentListModal] = useState<boolean>(false);
    const [taskWithoutBranchMapping, setTaskWithoutBranchMapping] = useState<any>(null);
    const [selectedReportList, setSelectedReportList] = useState<any>(null);
	const [showReportsModal, setShowReportsModal] = useState<boolean>(false);

    const taskHistoryFilter = [
        { name: "6 Months", value: 6 },
        { name: "12 Months", value: 12 },
        { name: "18 Months", value: 18 },
        { name: "All", value: "all" },
    ];

    const taskStatuses = {
        1: 'Active',
        3: 'Closed',
        11: 'Completed',
        36: 'Inactive'
    }

    const {
        control,
        setValue,
        clearErrors,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            uniqueSystemId: "",
            reportBy: 1,
            output: true,
            include: false,
            taskFilter: 6,
            equipmentGroup: [],
            systems: [],
            buildings: [],
            inspectionTask: null,
            inspectionReport: null,
            equipmentTestStartDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            equipmentTestEndDate: new Date(),
            SendLog: false,
        },
    });

    const reportByFileds = [
        { name: "Equipment Group", value: 1 },
        { name: "Building", value: 2 },
        { name: "System", value: 3 },
    ];

    useEffect(() => {
        dispatch(getSystemList());
        dispatch(fetchLoggedInUserDetails());

        return(() => {
            setTaskWithoutBranchMapping(null);
            dispatch(setContractUpdated(false));
            dispatch(setSystemList([]));
            dispatch(clearSystemSummary(""));
        });
    }, [dispatch]);

    useEffect(() => {
        if (selectedSystemId) {
            dispatch(getSystemSummary(selectedSystemId, selectedTaskHistoryFilter));
        }
    }, [dispatch, selectedSystemId]);

    useEffect(() => {
        if (systemsList?.length > 0) {
            let system: any = [];
            systemsList.forEach((item: any) => {
                return system.push({ name: item.systemName, id: item.uniqueSystemId });
            });
            setSystemLookup(system);
            dispatch(clearSystemSummary(""));
            setPanelDetails([]);
            setBuildingDetails([]);
            setSystemDetailsLoader(false);
            setInspectionTaskDetails([]);
            setSelectedInspectionTasks([])
            setselectedEquipmentGroups([])
            setSelectedReports([]);
            setInspectionReportDetails([]);
            setTaskWithoutBranchMapping(null);
            if (defaultSystemDetails && Object.keys(defaultSystemDetails).length !== 0) {
                if (defaultSystemDetails?.userDefaults?.system?.uniqueSystemId) {
                    if (systemsList.find((item) => item.uniqueSystemId === defaultSystemDetails?.userDefaults?.system?.uniqueSystemId)) {
                        setValue("uniqueSystemId", defaultSystemDetails?.userDefaults?.system?.uniqueSystemId);
                        setSelectedSystemId(defaultSystemDetails?.userDefaults?.system?.uniqueSystemId);
                    } else {
                        setValue("uniqueSystemId", systemsList[0]?.uniqueSystemId);
                        setSelectedSystemId(systemsList[0]?.uniqueSystemId);
                        dispatch(setDefaultSystemDetails(systemsList[0]?.uniqueSystemId))
                    }
                } else {
                    setValue("uniqueSystemId", systemsList[0]?.uniqueSystemId);
                    setSelectedSystemId(systemsList[0]?.uniqueSystemId);
                    dispatch(setDefaultSystemDetails(systemsList[0]?.uniqueSystemId))
                }
            }
        }
        else if(systemsList === null || systemsList === undefined)
        {
            setSystemLookup([]);    
            setSelectedSystemId(null);
        }
    }, [systemsList, defaultSystemDetails]);

    useEffect(() => {
        if (systemSummary) {
            setBuildingDetails(systemSummary?.buildingDetails);
            setInspectionTaskDetails(systemSummary?.inspectionTaskDetails);
            setPanelDetails(systemSummary?.panelDetails);
            setSystemDetailsLoader(true);
            let data: any = [];
            systemSummary?.panelDetails.forEach((element: any) => {
                data.push(element?.uniquePanelId);
            });
            setSelectedSystems(data);
            if (contractUpdated) {
                setShowEquipmentListModal(false);
            }
        }
    }, [systemSummary]);

    useEffect(() => {
        if (generatedReportDetais?.status === "done") {
            setDisplayInfoModal(true);
            setMessage(generatedReportDetais?.data?.responseMessage);
            dispatch(clearGeneratedReportDetails(""))
        }
    }, [generatedReportDetais]);

    useEffect(() =>{

        if(toggleGenerateClick) {
            onGenerateButtonClick()
            setToggleGenerateClick(false);

        }

    },[toggleGenerateClick])

    const onContractDateClick = (event) => {
        event?.preventDefault();
    };

    const onSystemLookupChange = (data: any) => {
        setSelectedSystemId(data);
        dispatch(setDefaultSystemDetails(data));
        setPanelDetails([]);
        setBuildingDetails([]);
        setSystemDetailsLoader(false);
        setSelectedBuildings([])
        setInspectionTaskDetails([]);
        setSelectedReports([]);
        setSelectedInspectionTasks([]);
        setselectedEquipmentGroups([]);
        setSelectedSystems([]);
        setInspectionReportDetails([]);
        setTaskWithoutBranchMapping(null);
    };

    const onEquipmentGroupSelected = (event: any) => {
        let data = [...selectedEquipmentGroups];

        if (event.checked) {
            data.push(event.value);
        } else {
            for (let i = 0; i < data.length; i++) {
                const selectedData = data[i];
                if (selectedData === event.value) {
                    data.splice(i, 1);
                    break;
                }
            }
        }
        setselectedEquipmentGroups(data);
        // setValue("equipmentGroup", data);
        clearErrors("equipmentGroup");
    };
    const onBuildingsSelected = (event: any) => {
        let data = [...selectedBuildings];
        if (event.checked) {
            data.push(event.value);
        } else {
            for (let i = 0; i < data.length; i++) {
                const selectedData = data[i];
                if (selectedData === event.value) {
                    data.splice(i, 1);
                    break;
                }
            }
        }
        setSelectedBuildings(data);
        // setValue("buildings", data);
        clearErrors("buildings");
    };
    const onSystemsSelected = (event: any) => {
        let data = [...selectedSystems];

        if (event.checked) {
            data.push(event.value);
        } else {
            for (let i = 0; i < data.length; i++) {
                const selectedData = data[i];
                if (selectedData === event.value) {
                    data.splice(i, 1);
                    break;
                }
            }
        }
        setSelectedSystems(data);
        // setValue("systems", data);
        clearErrors("systems");
    };

    useEffect(()=>{
        setValue("systems", selectedSystems);
    },[selectedSystems])

    useEffect(()=>{
        setValue("buildings", selectedBuildings);
    },[selectedBuildings])

    useEffect(()=>{
        setValue("equipmentGroup", selectedEquipmentGroups);
    },[selectedEquipmentGroups])


    const onInspectionTaskSelected = (event: any) => {
        let data = [...selectedInspectionTasks];

        if (event.checked) {
            data.push(event.value);
        } else {
            for (let i = 0; i < data.length; i++) {
                const selectedData = data[i];
                if (selectedData === event.value) {
                    data.splice(i, 1);
                    break;
                }
            }
        }
        setSelectedInspectionTasks(data);
        setValue("inspectionTask", data);
        clearErrors("inspectionTask");
        let reportData: any = [];
        data.forEach((taskId) => {
            const data = inspectionTaskDetails.find((item: any) => item?.uniqueInspectionTaskId === taskId);
            reportData.push(...data?.reportDetails);
        });
        reportData = reportData.filter((value, index, item) => index === item.findIndex((data) => data.inspectionReportId === value.inspectionReportId));
        setInspectionReportDetails(reportData);
    };

    const onReportsSelected = (event: any) => {
        let data = [...selectedReports];
        if (event.checked) {
            data.push(event.value);
        } else {
            for (let i = 0; i < data.length; i++) {
                const selectedData = data[i];
                if (selectedData === event.value) {
                    data.splice(i, 1);
                    break;
                }
            }
        }
        setSelectedReports(data);
        setValue("inspectionReport", data);
        clearErrors("inspectionReport");
    };

    const onGenerateReportFormSubmit = (data: any) => {
        if (new Date(data.equipmentTestStartDate) > new Date(data.equipmentTestEndDate)) {
            setMessage("End Date must be greater than Start Date");
            setDisplayInfoModal(true);
        } else if (taskWithoutBranchMapping && ((taskWithoutBranchMapping.inspectionTaskStatus === 3) || (taskWithoutBranchMapping.inspectionTaskStatus === 11) || (taskWithoutBranchMapping.inspectionTaskStatus === 36))) {
            if (taskWithoutBranchMapping) {
                if (taskWithoutBranchMapping.inspectionTaskStatus === 3) {
                    setMessage("Selected Inspection Task is closed. Activate the Task to add Branch details.");
                    setDisplayInfoModal(true);
                } else if (taskWithoutBranchMapping.inspectionTaskStatus === 11) {
                    setMessage("Selected Inspection Task is completed. Activate the Task to add Branch details.");
                    setDisplayInfoModal(true);
                } else if (taskWithoutBranchMapping.inspectionTaskStatus === 36) {
                    setMessage("Selected Inspection Task is intactive. Activate the Task to add Branch details.");
                    setDisplayInfoModal(true);
                }
            }
        } else {
            const body = {
                uniqueSystemId: data.uniqueSystemId,
                reportBy: data.reportBy,
                panelIds: data.reportBy === 1 ? data.equipmentGroup : data.reportBy === 3 ? data.systems : [],
                buildingIds: data.reportBy === 2 ?data.buildings:[],
                uniqueInspectionTaskIds: data.inspectionTask,
                reportIds: data.inspectionReport,
                startDate: moment(new Date(data.equipmentTestStartDate)).format("YYYY-MM-DD").toString(),
                EndDate: moment(new Date(data.equipmentTestEndDate)).format("YYYY-MM-DD").toString(),
                generateLog: data.SendLog,
                includealldevices: data.include,
                outputnodeId: data.output,
                taskHistoryFilterId: selectedTaskHistoryFilter==="all" ? 1 : selectedTaskHistoryFilter,
                debugflag: data.SendLog,
            };
            dispatch(setProgressFlag(true));
            dispatch(onGenerateReport(selectedSystemId, body));
        }
    };

    const onError = (errors: any, e: any) => {};

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="error-show">
                    <BsExclamationCircleFill />
                    <span>{errors[name].message}</span>
                </span>
            )
        );
    };

    useEffect(()=>{
        if(selectedTaskHistoryFilter && selectedSystemId){
            dispatch(getSystemSummary(selectedSystemId, selectedTaskHistoryFilter));
            setPanelDetails([]);
            setBuildingDetails([]);
            setSystemDetailsLoader(false);
            setInspectionTaskDetails([]);
            setSelectedReports([]);
            setSelectedInspectionTasks([]);
            setselectedEquipmentGroups([]);
            setSelectedBuildings([]);
            setSelectedSystems([]);
            setselectedEquipmentGroups([]);
            setInspectionReportDetails([]);
            setTaskWithoutBranchMapping(null);
        }
    }, [selectedTaskHistoryFilter]);

    const selectTaskWithoutBranchMapping = (taskSelected) => {
        setContractUpdated(false);
        let task = {...taskSelected}
        task.status = taskStatuses[task.inspectionTaskStatus];
        if (selectedSystemId) {
            task.uniqueSystemId = selectedSystemId;
            let item = systemLookup.find(element => element.id === task.uniqueSystemId);
            if (item) {
                task.system = {uniqueSystemId: item.id};
                task.systemName = item.name;
                task.systemNames = item.name;
            }
        }
        dispatch(getSelectedReportList(task.uniqueInspectionTaskId));
        setTaskWithoutBranchMapping(task);
        if (taskSelected.inspectionTaskStatus === 1) {
            setShowEquipmentListModal(true);
        } else if (taskWithoutBranchMapping) {
            if (taskWithoutBranchMapping.inspectionTaskStatus === 3) {
                setMessage("The selected Task is closed. Please Activate the Task to add Branch details.");
                setDisplayInfoModal(true);
            } else if (taskWithoutBranchMapping.inspectionTaskStatus === 11) {
                setMessage("The selected Task is completed. Please Activate the Task to add Branch details.");
                setDisplayInfoModal(true);
            } else if (taskWithoutBranchMapping.inspectionTaskStatus === 36) {
                setMessage("The selected Task is intactive. Please Activate the Task to add Branch details.");
                setDisplayInfoModal(true);
            }
        }
    }

    useEffect(() => {
		if (reportList != "") {
            setSelectedReportList(reportList.reportDetails);
		}
	}, [reportList]);

    useEffect(() => {
        if (contractUpdated && selectedSystemId) {       
            dispatch(getSystemSummary(selectedSystemId, selectedTaskHistoryFilter));

        }
    }, [contractUpdated]);

    const saveReportClick = (selections) => {
		let reports: any = [];
        selections.map((item: any) => {
		  return reports.push(item.inspectionReportId.toString());
		});
		dispatch(onUpdateReportSelected(reports, taskWithoutBranchMapping.uniqueInspectionTaskId, setMessage, setDisplayInfoModal, setShowReportsModal, selections, setSelectedReportList));
	};

    const onGenerateButtonClick = () =>{
        generateReportForm.current.requestSubmit()
    }

    return (

        <div className="generate-reports-wrapper">
            <form onSubmit={handleSubmit(onGenerateReportFormSubmit, onError)} ref={generateReportForm}>

                    <div className="generate-reports d-flex row">
                    <div className="data-wrapper-scroll">
                        <div className="row">
                        <div className="col-6">
                            <div className="row px-2 pt-2">
                                <div className="field col-12 md:col-2 mb-0">
                                    <span className="p-float-label">
                                        <Controller
                                            name="uniqueSystemId"
                                            control={control}
                                            rules={{
                                                required: "Select a System",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    inputId="system"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        onSystemLookupChange(e.value);
                                                    }}
                                                    options={systemLookup}
                                                    optionLabel="name"
                                                    optionValue="id"
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="Country">
                                            System
                                        </label>
                                    </span>
                                    {getFormErrorMessage("uniqueSystemId")} 
                                </div>

                                <div className="field col-12 md:col-2 mb-0">
                                    <label className="title" htmlFor="radiobutton">
                                        Report by
                                    </label>

                                    <div className="radio-section d-flex align-items-center pt-2">
                                        <Controller
                                            name="reportBy"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    {reportByFileds.length > 0
                                                        ? reportByFileds.map((item: any, key: any) => {
                                                              return (
                                                                  <div className="field-radiobutton pl-2" key={key}>
                                                                      <RadioButton
                                                                          {...field}
                                                                          key={key}
                                                                          inputId={item.value}
                                                                          value={reportBy?.value}
                                                                          onChange={() => {
                                                                              setReportBy(item);
                                                                              setValue("reportBy", item.value);
                                                                          }}
                                                                          checked={reportBy?.name === item?.name}
                                                                      />
                                                                      <label htmlFor={item?.value}>{item?.name}</label>
                                                                  </div>
                                                              );
                                                          })
                                                        : null}
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className="border-box">
                                        <label className="title" htmlFor="radiobutton">
                                            {(reportBy && reportBy.name === 'Building') ? 'Building' : 'Equipment Group'}
                                        </label>

                                        {reportBy?.name === "Equipment Group" ? (
                                            <div className="report-min-height">
                                                <Controller
                                                    name="equipmentGroup"
                                                    control={control}
                                                    rules={{
                                                        required: "Atleast one panel should be selected",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            {panelDetails.length > 0
                                                                ? panelDetails.map((panel: any, key: any) => {
                                                                    return (
                                                                        <div className="field-checkbox pt-2" key={key}>
                                                                            <Checkbox
                                                                                {...field}
                                                                                key={key}
                                                                                inputId={panel?.uniquePanelId}
                                                                                value={panel?.uniquePanelId}
                                                                                onChange={(e) => {
                                                                                    onEquipmentGroupSelected(e);
                                                                                }}
                                                                                checked={selectedEquipmentGroups.some(
                                                                                    (item) => item === panel?.uniquePanelId
                                                                                )}
                                                                            />
                                                                            <label className="text-uppercase" htmlFor={panel?.uniquePanelId}>
                                                                                {panel?.panelName}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                }) :
                                                                (systemDetailsLoader && <span className="error-show checkbox-error"><BsExclamationCircleFill />No Equipment Group exists</span>)
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        ) : reportBy?.name === "Building" ? (
                                            <div className="report-min-height">
                                                <Controller
                                                    name="buildings"
                                                    control={control}
                                                    rules={{
                                                        required: "Atleast one Building should be selected.",
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            {buildingDetails.length > 0 ?
                                                                buildingDetails.map((building: any, key: any) => {
                                                                    return (
                                                                        <div className="field-checkbox pt-2" key={key}>
                                                                            <Checkbox
                                                                                {...field}
                                                                                key={key}
                                                                                inputId={building?.buildingId}
                                                                                value={building?.buildingId}
                                                                                onChange={(e) => {
                                                                                    onBuildingsSelected(e);
                                                                                }}
                                                                                checked={selectedBuildings.some((item) => item === building?.buildingId)}
                                                                            />

                                                                            <label className="text-uppercase" htmlFor={building?.buildingId}>
                                                                                {building?.buildingName}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                }) : 
                                                                (systemDetailsLoader && <span className="error-show checkbox-error"><BsExclamationCircleFill />No Building exists</span>)
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        ) : reportBy?.name === "System" ? (
                                            <div className="report-min-height">
                                                <Controller
                                                    name="systems"
                                                    control={control}
                                                    rules={{
                                                        required: "Atleast one panel should be selected",
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            {panelDetails.length > 0 ?
                                                                panelDetails.map((panel: any, key: any) => {
                                                                    return (
                                                                        <div className="field-checkbox pt-2" key={key}>
                                                                            <Checkbox
                                                                                {...field}
                                                                                key={key}
                                                                                inputId={panel?.uniquePanelId}
                                                                                value={panel?.uniquePanelId}
                                                                                onChange={(e) => {
                                                                                    onSystemsSelected(e);
                                                                                }}
                                                                                checked={selectedSystems.some((item) => item === panel?.uniquePanelId)}
                                                                            />
                                                                            <label className="text-uppercase" htmlFor={panel?.uniquePanelId}>
                                                                                {panel?.panelName}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                }) :
                                                                (systemDetailsLoader && <span className="error-show checkbox-error"><BsExclamationCircleFill />No System exists</span>)
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        ) : null}
                                           
                                    </div>
                                    {getFormErrorMessage("equipmentGroup")}
                                    {getFormErrorMessage("buildings")}
                                    {getFormErrorMessage("systems")}

                                </div>

                                <div className="field col-12 md:col-2 ">
                                    <div className="field-checkbox pt-3">
                                        <Controller
                                            name="output"
                                            control={control}
                                            render={({ field }) => <Checkbox inputId="output" checked={field.value} {...field} />}
                                        />
                                        <label htmlFor="output">Output the Equipment Group with the Device Address</label>
                                    </div>
                                    <div className="field-checkbox pt-3">
                                        <Controller
                                            name="include"
                                            control={control}
                                            render={({ field }) => <Checkbox inputId="include" checked={field.value} {...field} />}
                                        />
                                        <label htmlFor="include">Include Untested Devices</label>
                                    </div>
                                </div>

                                <div className="field col-12 md:col-2">
                                    <span className="p-float-label">
                                        <Controller
                                            name="taskFilter"
                                            control={control}
                                            rules={{
                                                required: "This field is required",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    inputId="taskFilter"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    {...field}
                                                    value={selectedTaskHistoryFilter}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setSelectedTaskHistoryFilter(e.value);
                                                    }}
                                                    options={taskHistoryFilter}
                                                    optionLabel="name"
                                                    optionValue="value"
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="Country">
                                            Task History Filter
                                        </label>
                                        {getFormErrorMessage("taskFilter")} 
                                    </span>
                                </div>

                                <div className="field col-12 md:col-2 mb-0">
                                    <div className="border-box">
                                        <label className="title" htmlFor="radiobutton">
                                            Task
                                        </label>
                                        {inspectionTaskDetails && inspectionTaskDetails.length > 0 ? (
                                            <div className="report-min-height">
                                            <Controller
                                                name="inspectionTask"
                                                control={control}
                                                rules={{
                                                    required: "At least one Task should be selected"
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        {inspectionTaskDetails.map((task: any, key: any) => {
                                                            if ((task?.hasBranch === true && task?.hasBranchMapping === true)||(task?.hasBranch === false && task?.hasBranchMapping === false)||(task?.hasBranch === true && task?.hasBranchMapping === false)) {
                                                                return (
                                                                    <div className="field-checkbox pt-2" key={key}>
                                                                        <Checkbox
                                                                            {...field}
                                                                            key={key}
                                                                            inputId={task.uniqueInspectionTaskId}
                                                                            value={task?.uniqueInspectionTaskId}
                                                                            onChange={(e) => {
                                                                                onInspectionTaskSelected(e);
                                                                            }}
                                                                            checked={selectedInspectionTasks.some(
                                                                                (item) => item === task?.uniqueInspectionTaskId
                                                                            )}
                                                                        />

                                                                        <label 
                                                                            htmlFor={task?.uniqueInspectionTaskId} 
                                                                        >
                                                                            {task?.inspectionTaskName}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <div className="field-checkbox pt-2" key={key}>
                                                                            <Controller
                                                                                name="inspectionTask"
                                                                                key={key}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        key={key}
                                                                                        disabled={true}
                                                                                        inputId={task.uniqueInspectionTaskId}
                                                                                        value={task?.uniqueInspectionTaskId}
                                                                                        onChange={(e) => {
                                                                                            onInspectionTaskSelected(e);
                                                                                        }}
                                                                                        checked={selectedInspectionTasks.some(
                                                                                            (item) => item === task?.uniqueInspectionTaskId
                                                                                        )}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            <label 
                                                                                className="no-branch-office" 
                                                                                htmlFor={task?.uniqueInspectionTaskId}
                                                                                onClick={() => selectTaskWithoutBranchMapping(task)}
                                                                            >
                                                                                {task?.inspectionTaskName}
                                                                            </label>
                                                                        </div>
                                                                        <div className="branch-office-validation">
                                                                            <span>Note: </span>
                                                                            Select the required Branch Office by clicking on the Task Name
                                                                        </div>
                                                                    </>
                                                                );
                                                            }
                                                        })}
                                                    </>
                                                )}
                                            />
                                            </div>
                                        ) : null}
                                    </div>
                                    {getFormErrorMessage("inspectionTask")} 
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="row px-2 pt-2">
                                <div className="field col-12 md:col-2">
                                    <div className="border-box">
                                        <label className="title" htmlFor="radiobutton">
                                            Reports
                                        </label>
                                        {inspectionReportDetails && inspectionReportDetails.length > 0 ? (
                                            <div className="report-min-height">
                                            <Controller
                                                name="inspectionReport"
                                                control={control}
                                                rules={{
                                                    required: "Atleast one report should be selected",
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        {inspectionReportDetails.map((report: any, key: any) => {
                                                            return (
                                                                <div className="field-checkbox pt-2" key={key}>
                                                                    <Checkbox
                                                                        {...field}
                                                                        key={key}
                                                                        inputId={report.inspectionReportId}
                                                                        value={report?.inspectionReportCode}
                                                                        onChange={(e) => {
                                                                            onReportsSelected(e);
                                                                        }}
                                                                        checked={selectedReports.some((item) => item === report?.inspectionReportCode)}
                                                                    />

                                                                    <label className="text-uppercase" htmlFor={report?.inspectionReportId}>
                                                                        {report?.inspectionReportName}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            />
                                            </div>
                                        ) : null}
                                    </div>
                                    {getFormErrorMessage("inspectionReport")} 
                                </div>
                                <div className="field col-6 md:col-2 pt-3">
                                    <span onDoubleClick={onContractDateClick} className="p-float-label">
                                        <Controller
                                            name="equipmentTestStartDate"
                                            control={control}
                                            rules={
                                                {
                                                    // required: "This field is required.",
                                                }
                                            }
                                            render={({ field, fieldState }) => (
                                                <Calendar
                                                    id="equipmentTestStartDate"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    dateFormat="yy-mm-dd"
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="calendar">
                                            Start Date
                                        </label>
                                    </span>
                                </div>

                                <div className="field col-6 md:col-2 pt-3">
                                    <span onDoubleClick={onContractDateClick} className="p-float-label">
                                        <Controller
                                            name="equipmentTestEndDate"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Calendar
                                                    id="equipmentTestEndDate"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    dateFormat="yy-mm-dd"
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="calendar">
                                            End Date
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
              
            </form>
            <div className="footer-btn text-left">
                {showUI(userPrivileges, "SIETAP_INSPECTIONS_REPORTGENERATION_SENDLOG") &&
                    <div className="field-checkbox">
                        <Controller
                            name="SendLog"
                            control={control}
                            render={({ field }) => <Checkbox inputId="SendLog" checked={field.value} {...field} />}
                        />
                        <label htmlFor="SendLog">Send log file to mail while generating reports</label>
                    </div>
                }
            </div>
          
            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />


            {showEquipmentListModal &&
                <InspectionDetails
                    showModal={showEquipmentListModal}
                    setShowModal={setShowEquipmentListModal}
                    selectedTask={taskWithoutBranchMapping}
					selectedReports={selectedReportList}
					setShowReportModal={setShowReportsModal}
                    taskWithoutBranchOffice={true}
                />
            }

			{showReportsModal && (
				<InspectionReports
					showInfoCard
					showModal={showReportsModal}
					setShowModal={setShowReportsModal}
					selectedReports={selectedReportList}
					isAdd={false}
					selectedRows={[taskWithoutBranchMapping]}
					saveReportClick={saveReportClick}
				/>
			)}
        </div>
    );
};

export default GenerateReports;
