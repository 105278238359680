import React, { useCallback, useEffect, useState, memo } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import Filters from "components/Filters/Filters";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

import { InspectionTableData, INSPECTION_TASKS_Extra_COLS } from "./InspectionTasksData";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import {
  getInspectionTaskList,
  getInspectionSearchList,
  onAddInspectionTask,
  onEditInspectionTask,
  deleteInspectionTask,
  checkFinalReportUploadStatus,
  checkInspectionTaskForClose,
  checkInspectionTaskForComplete,
  getSelectedReportList,
  onUpdateReportSelected,
  onCloseInspectionTask,
  onCompleteInspectionTask,
  getTechnicians,
  setUpdatedInspectionData,
  getInspectionTaskTechnicians,
  quitTask,
  getImportPreviousTaskDetails,
  compareTasks,
  setNewInspectionData,
  inActivateInspectionTask,
  setDefaultFontSize,
  setReportUploadedStatus,
  setInspectionSearchId,
  setInspectionPageDetails,
  setInspectionTaskList,
  setInspectionTaskDeletedData
} from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { checkSystemInUse, setSystemInUse } from 'redux/pages/Inspection/Systems/systemSlice';
import AddInspectionTask from "./ManageInspectionTasks/AddInspectionTask";
import DrawingList from "./ManageInspectionTasks/DrawingList";
import InspectionReports from "./ManageInspectionTasks/InspectionReports";
import EditInspectionTask from "./ManageInspectionTasks/EditInspectionTask";
import InspectionDetails from "./ManageInspectionTasks/InspectionDetails/InspectionDetails";
import InspectionDrawingsModal from "./InspectionDrawings/InspectionDrawingsModal";
import DataTableComponent from "components/Table/DataTable";
import PendingReports from "./ManageInspectionTasks/PendingReports";
import {
  BsCheckCircleFill,
  BsFillPencilFill,
  BsGridFill,
  BsPlusLg,
  BsTrashFill,
  BsXCircleFill,
} from "react-icons/bs";
import { Button } from "primereact/button";
import { FaChevronDown, FaCog, FaDownload } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { getContractDetails } from "redux/pages/Inspection/Contracts/contractSlice";
import ImportPreviousTaskDetails from "./ImportPreviousTaskDetails/ImportPreviousTaskDetails";
import QuitTask from "./QuitTask/QuitTask";
import moment from "moment";
import CompareInspectionTasks from "./CompareInspectionTasks/CompareInspectionTasks";
import { showUI } from "service/GeneralUtility";
import PopOver from "components/common/PopOver/PopOver";
import InspectionTaskReport from "pages/InspectionTasks/Report/InspectionTaskReport";
import EquipmentSummary from "./EquipmentSummary/EquipmentSummary"
import { onGetUserRoles } from "redux/pages/Administration/LogBook/logBookSlice";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface InspectionTaskProps {
  searchText?: string;
}

export const InspectionTasks: React.FC<InspectionTaskProps> = () => {
  const settingRef = React.createRef<OverlayPanel>();
  const MONTHS_LIST = [
    { name: "Last 3 Months", code: "1" },
    { name: "Last 6 Months", code: "2" },
    { name: "Last 12 Months", code: "3" },
    { name: "Last 18 Months", code: "4" },
    { name: "All", code: "0" },
  ];

  const STATUS_MAP = {
    Active: "Active",
    Closed: "Closed",
    Completed: "Completed",
  };

  const inspectionTaskList = useAppSelector((state) => state.inspectionTask.inspectionTaskList);
  const activeSite = useAppSelector((state) => state.administration.loggedInUserDetails.userDefaults?.siteName);
  const defaultFontClass = useAppSelector((state) => state.administration.loggedInUserDetails.userDefaults?.fontClass);
  const inspectionPageDetails = useAppSelector((state) => state.inspectionTask.inspectionPageDetails);
  const isInspectionTaskDeleted = useAppSelector((state) => state.inspectionTask.isInspectionTaskDeleted);
  const inspectionTaskSearchId = useAppSelector((state) => state.inspectionTask.inspectionSearchId);
  const reportUploadedStatus = useAppSelector((state) => state.inspectionTask.reportUploadedStatus);
  const selectedCustomerId = useAppSelector((state) => state.inspectionTaskFilter.selectedCustomerId);
  const selectedContractId = useAppSelector((state) => state.inspectionTaskFilter.selectedContractId);
  const isCustomerAll = useAppSelector((state) => state.inspectionTaskFilter.isCustomerAll);
  const isContractAll = useAppSelector((state) => state.inspectionTaskFilter.isContractAll);
  const inspectionTasksRowsPerPage = useAppSelector((state) => state.personalSettings.inspectionTasksRowsPerPage);
  const selectedCustomerDetails = useAppSelector((state) => state.inspectionTaskFilter.selectedCustomerDetails);
  const selectedContractDetails = useAppSelector((state) => state.inspectionTaskFilter.selectedContractDetails);
  const selectedFailedEquipment = useAppSelector((state) => state.dashboard.selectedFailedEquipment);

  const newInspectionTask = useAppSelector((state) => state.inspectionTask.newInspectionData);
  const updatedInspectionTask = useAppSelector((state) => state.inspectionTask.updatedInspectionData);
  const systemInUse = useAppSelector((state) => state.system.systemInUse);
  const selectedReportList = useAppSelector((state) => state.inspectionTask.selectedReportList);
  const selectedSite = useAppSelector((state) => state.inspectionTaskFilter.selectedSite);

  const checkedDataForClose = useAppSelector((state) => state.inspectionTask.checkedDataForClose);
  const checkedDataForComplete = useAppSelector((state) => state.inspectionTask.checkedDataForComplete);
  const inspectionTaskStatus = useAppSelector((state) => state.inspectionTask.inspectionTaskStatus);

  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  const loggedInUserDetailsStatus = useAppSelector((state) => state.administration.loggedInUserDetailsStatus);
  const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);

  const contractDetails = useAppSelector((state) => state.contract.contractDetails);
  const technicianData = useAppSelector((state) => state.inspectionTask.technicianData);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterExpanded, setFilterExpanded] = useState<any>(true);
  const [toggleActiveTask, setToggleActiveTask] = useState<any>(true);
  const [toggleClosedTask, setToggleClosedTask] = useState<any>(true);
  const [toggleCompletedTask, setToggleCompletedTask] = useState<any>(true);
  const [selectedStatus, setSelectedStatus] = useState<any>([
    STATUS_MAP.Active,
    STATUS_MAP.Closed,
    STATUS_MAP.Completed,
  ]);
  const [selectedMonth, setSelectedMonth] = useState<any>(
    MONTHS_LIST[MONTHS_LIST.length - 2]
  );
  const [taskDataInfoList, setTaskDataInfoList] = useState<any>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [customerAllChecked, setCustomerAllChecked] = useState<boolean>();
  const [contractAllChecked, setContractAllChecekd] = useState<boolean>();
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [showDrawingList, setShowDrawingList] = useState<boolean>(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [showReportsModal, setShowReportsModal] = useState<boolean>(false);
  const [showPendingReportModal, setShowPendingReportModal] = useState<boolean>(false);
  const [selectedReports, setSelectedReports] = useState<any>(null);
  const [showEquipmentListModal, setShowEquipmentListModal] = useState<boolean>(false);
  const [inspectionTaskData, setInspectionTaskData] = useState<any>([]);
  const [inspectionTaskCols, setInspectionTaskCols] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<Array<any>>([]);
  const [isAddSubmit, setIsAddSubmit] = useState(false);
  const [isEditSubmit, setIsEditSubmit] = useState(false);
  const [isReportSelected, setIsReportSelected] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contractName, setContractName] = useState("");
  const [showAddIcon, setShowAddIcon] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const [fontClass, setFontClass] = useState<any>(defaultFontClass);
  const [isSmallFont, setSmallFont] = useState<boolean>(false);
  const [isMediumFont, setMediumFont] = useState<boolean>(true);
  const [isLargeFont, setLargeFont] = useState<boolean>(false);
  const [isFontChange, setIsFontChange] = useState<boolean>(false);
  const [isImportPrevious, setIsImportPrevious] = useState<boolean>(false);
  const [gridReadyToRefresh, setGridReadyToRefresh] = useState<boolean>(false);

  const [isTaskReadyToClose, setIsTaskReadyToClose] = useState(false);
  const [isTaskReadyToComplete, setIsTaskReadyToComplete] = useState(false);
  const [pendingReportList, setPendingReportsList] = useState([]);
  const [showDrawingModal, setShowDrawingModal] = useState<boolean>(false);
  const [currentStart, setCurrentStart] = useState(1);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [inspectionRowData, setInspectionRowData] = useState<any>("");
  const [isRowEdit, setIsRowEdit] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImportPreviousTaskDetails, setShowImportPreviousTaskDetails] = useState<boolean>(false);
  const [showQuitTask, setShowQuitTask] = useState<boolean>(false);
  const [previousTaskDetails, setPreviousTaskDetails] = useState<any>([]);
  const [displayPreviousTaskConfirmModal, setDisplayPreviousTaskConfirmModal] = useState<any>(false);
  const [inactivateFlag, setInactivateFlag] = useState(false);
  const [comparisonData, setComparisonData] = useState<any>({});
  const [comparisonModal, setComparisonModal] = useState(false);
  const [isActiveContract, setIsActiveContract] = useState(true);
  const [disableIcons, setDisableIcons] = useState(false);
  const [settingListItems, setSettingListItems] = useState<any>()
  const [comparisonCurrentStart, setComparisonCurrentStart] = useState<any>(1);
  const [comparisonPageIndex, setComparisonPageIndex] = useState(0);
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [countryId, setCountryId] = useState<any>();
  const [showEquipmentSummaryModal, setShowEquipmentSummaryModal] = useState<boolean>(false);
  const [controller, setController] = useState(new AbortController());
  const downloadRef = React.createRef<OverlayPanel>();
  const dispatch = useDispatch<any>();
  const [pageDetails, setPageDetails] = useState<any>({});

  useEffect(() => {
    setCustomerAllChecked(isCustomerAll)
  }, [isCustomerAll]);

  useEffect(() => {
    if (inspectionTaskSearchId.length > 0) {
      setIsSearch(true);
    }
    return () => { dispatch(setInspectionSearchId("")); }
  }, [inspectionTaskSearchId]);

  useEffect(() => {
    if (userPrivileges && userPrivileges.length) {
      filterSettingItemList();
    }
    if (selectedRows && selectedRows.length && selectedFailedEquipment && inspectionTaskSearchId.length > 0) {   
      if ((selectedRows.length === 1) && (selectedRows[0].uniqueInspectionTaskId === inspectionTaskSearchId[0].uniqueInspectionTaskId)) {
        onShowEquipmentListModel();
        dispatch(setInspectionSearchId(""));
      }
    }
  }, [userPrivileges, selectedRows]) 

  useEffect(() => {
    if (loggedInUserDetails) {
      setCountryId(loggedInUserDetails?.userDefaults?.countryId);
    }
  }, [loggedInUserDetails])

  const filterSettingItemList = () => {
    const settingList: any = [];
    userPrivileges.map(element => {
      if (element === 'SIETAP_INSPECTIONS_IMPORTPREVIOUS') {
        settingList.push({
          id: 1,
          name: 'Import Previous Task Details',
          onClick: () => { checkImportPreviousTaskDetails(selectedRows) }
        })
      } else if (element === 'SIETAP_INSPECTIONS_QUITTASK') {
        settingList.push({
          id: 2,
          name: 'Quit Task',
          onClick: () => { onQuitTaskClick(selectedRows) }
        })
      } else if (element === 'SIETAP_INSPECTIONS_INACTIVATE') {
        settingList.push({
          id: 3,
          name: 'Inactivate',
          onClick: () => { onInactivateClick(selectedRows) }
        })
      } else if (element === 'SIETAP_INSPECTIONS_REPORTSSELECTED') {
        settingList.push({
          id: 5,
          name: 'Reports Selected',
          onClick: () => { onReportsSelectedClick(selectedRows) }
        })
      } else if (element === 'SIETAP_INSPECTIONS_COMPARE') {
        settingList.push({
          id: 4,
          name: 'Compare',
          onClick: () => { onCompareClick(selectedRows) }
        })
      }
    });
    settingList.push({
      id: 6,
      name: 'Drawings',
      onClick: () => { onDrawingsClicked() }
    });
    settingList.push({
      id: 7,
      name: 'Equipment Summary',
      onClick: () => { 
        if (selectedRows.length < 1) {
          setMessage("Select an Inspection Task");
          setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
          setMessage("This operation can be performed only one row at a time");
          setDisplayInfoModal(true);
        }
        else{setShowEquipmentSummaryModal(true) }       
      }
    })
    setSettingListItems(settingList)
  }

  useEffect(() => {
    setContractAllChecekd(isContractAll)
  }, [isContractAll]);

  useEffect(() => {
    if (isDelete && reportUploadedStatus?.inspectionTaskName) {
      if (reportUploadedStatus.isFinalReportUploaded) {
        if (showUI(userPrivileges, "SIETAP_INSPECTIONS_DELETE_FINISHED_TASK")) {
          setMessage("Are you sure you want to delete the selected Inspection Task?");
          setDisplayConfirmModal(true);
        } else {
          setMessage("You do not have the permission to delete this Inspection Task");
          setDisplayInfoModal(true);
        }
      } else {
        setMessage("Are you sure you want to delete the selected Inspection Task?");
        setDisplayConfirmModal(true);
      }
    }
  }, [isDelete, reportUploadedStatus]);

  useEffect(() => {
    if (loggedInUserDetailsStatus && loggedInUserDetailsStatus === 'success') {
      if (customerAllChecked || contractAllChecked) {
        setInspectionTaskCols([]);
        setInspectionTaskCols([INSPECTION_TASKS_Extra_COLS, ...tableColumns].flat());
      } else if (!customerAllChecked && !contractAllChecked) {
        setInspectionTaskCols(tableColumns);
      }
          controller.abort();
          const newController = new AbortController();
          setController(newController);
      if ((selectedCustomerId && selectedContractId) || customerAllChecked || (!customerAllChecked && contractAllChecked)) {
        if (inspectionTaskSearchId.length === 0 && !isSearch) {
          let body = {
            uniqueCustomerId: selectedCustomerId,
            uniqueContractId: selectedContractId,
            isCustomerAll: isCustomerAll,
            isContractAll: isContractAll,
            active: toggleActiveTask,
            closed: toggleClosedTask,
            completed: toggleCompletedTask,
            dataFrom: selectedMonth.code,
          };
          setInspectionTaskData([]);
          setSelectedRows([]); 
          dispatch(getInspectionTaskList(body, 1, keyWord, sortField, sortOrder, newController));
          if (gridReadyToRefresh) {
            setGridReadyToRefresh(false);
          }
        } else if (inspectionTaskSearchId.length > 0 && isSearch) {
          setSelectedRows([]);
          dispatch(getInspectionSearchList(inspectionTaskSearchId[0].uniqueInspectionTaskId,inspectionTaskSearchId[0].searchBy));
          setIsSearch(false);
        }         
      } else {
        resetInspectionTaskGridAPICall(newController);
        setInspectionTaskData([]);
        setSelectedRows([]);
      }
      if (loggedInUserDetails?.userDefaults?.siteStatus === 'Active') {
        if (contractAllChecked || customerAllChecked) {
          setShowAddIcon(false);
        } else {
          setShowAddIcon(true);
        }
      } else {
        setShowAddIcon(false);
      }
    }
  }, [
    dispatch,
    isSearch,
    inspectionTaskSearchId,
    selectedContractId,
    toggleActiveTask,
    toggleClosedTask,
    toggleCompletedTask,
    customerAllChecked,
    contractAllChecked,
    selectedMonth,
    selectedSite,
    loggedInUserDetailsStatus,
    inspectionTasksRowsPerPage,
    gridReadyToRefresh
  ]);

  useEffect(() => {
    if (inspectionPageDetails) {
      setPageDetails(inspectionPageDetails);
    }
  }, [inspectionPageDetails]);

  useEffect(() => {
    if (inspectionTasksRowsPerPage && inspectionTasksRowsPerPage > 0) {
      let modifiedPageDetails = { ...pageDetails, 'currentLength': inspectionTasksRowsPerPage, 'pageCount': inspectionTasksRowsPerPage };
      setPageDetails(modifiedPageDetails);
      setCurrentStart(1)
      setPageIndex(0)
    }
  }, [inspectionTasksRowsPerPage])

  useEffect(() => {
    if (customerAllChecked || contractAllChecked) {
      setInspectionTaskCols([]);
      setInspectionTaskCols([INSPECTION_TASKS_Extra_COLS, ...tableColumns].flat());
    } else if (!customerAllChecked && !contractAllChecked) {
      setInspectionTaskCols(tableColumns);
    }
  }, [tableColumns])

  useEffect(() => {
    if (inspectionTaskList.length > 0) {
      setTaskDataInfoList(inspectionTaskList);
      dispatch(setInspectionTaskList(""));
    } else {
      setInspectionTaskData([]);
    }
  }, [inspectionTaskList]);

  useEffect(() => {
    if (taskDataInfoList?.length > 0) { 
      setSelectedRows([]);
      let listData = JSON.parse(JSON.stringify(taskDataInfoList))
      listData.map((item: any) => {
        item.plannedEndDate = moment(item.plannedEndDate).format("YYYY-MM-DD").toString()
        item.plannedStartDate = moment(item.plannedStartDate).format("YYYY-MM-DD").toString()
      })
      setInspectionTaskData(listData);
      if (inspectionTaskSearchId.length > 0 && inspectionTaskSearchId[0].uniqueInspectionTaskId) {
        let index = listData.findIndex(element => (element.uniqueInspectionTaskId === inspectionTaskSearchId[0].uniqueInspectionTaskId));      
        if (index !== -1) {
          handleRowSelect([listData[0]]);
        } else {
          //dispatch(setInspectionSearchId(""));   ----------- Purposefuly commented
        }
      } else {
        handleRowSelect([listData[0]]);
      }
      setDisableIcons(false);
    } else {
      setDisableIcons(true)
      setInspectionTaskData([]);
      setSelectedRows([]);
    }
  }, [taskDataInfoList]);

  useEffect(() => {
    let customer = "";
    if (selectedCustomerDetails && selectedCustomerId) {
      customer = `${selectedCustomerDetails?.customerName} (${selectedCustomerDetails?.customerNumber})`;
    } else {
      if (!customerAllChecked) {
        customer = "None";
      } else {
        customer = "All";
      }
    }
    setCustomerName(customer);
  }, [selectedCustomerDetails, selectedCustomerId, customerAllChecked]);

  useEffect(() => {
    let contract = "";
    if (selectedContractDetails) {
      contract = selectedContractDetails?.contractName;
      if (selectedContractDetails?.status === "Closed")
        setIsActiveContract(false);
      else
        setIsActiveContract(true);
    } else {
      if (!contractAllChecked && !customerAllChecked) {
        contract = "None";
      } else {
        contract = "All";
      }
    }
    setContractName(contract);
  }, [selectedContractDetails, contractAllChecked, customerAllChecked]);

  const inspectionGridColumnTemplate = (rowData, options) => {		
    return (<div className="d-flex align-items-center" dangerouslySetInnerHTML={{__html: `${rowData[options?.field]}` }} />);
	};

  const onPageChanged = (options: any) => {
    if (options !== pageIndex) {
      setPageIndex(options);
      let currentStart = (options * pageDetails?.currentLength) + 1
      setCurrentStart(currentStart)
      let body = {
        uniqueCustomerId: selectedCustomerId,
        uniqueContractId: selectedContractId,
        isCustomerAll: isCustomerAll,
        isContractAll: isContractAll,
        active: toggleActiveTask,
        closed: toggleClosedTask,
        completed: toggleCompletedTask,
        dataFrom: selectedMonth.code,
      };
      controller.abort();
      const newController = new AbortController();
      setController(newController);
      dispatch(getInspectionTaskList(body, currentStart, keyWord, sortField, sortOrder, newController));
    }
  };

  const onCompareClick = (selectedRows) => {
    setComparisonData({})
    if (selectedRows?.length === 1) {
      setMessage('Select two Inspection Tasks');
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 2) {
      if ((selectedRows[0]?.system?.uniqueSystemId) == (selectedRows[1]?.system?.uniqueSystemId)) {
        let body = {
          uniqueInspectionTaskId1: selectedRows[0]?.uniqueInspectionTaskId,
          uniqueInspectionTaskId2: selectedRows[1]?.uniqueInspectionTaskId,
          systemid: selectedRows[0]?.system?.uniqueSystemId
        };
        dispatch(compareTasks(body, 1, null, setComparisonData, setMessage, setDisplayInfoModal))
      } else {
        setMessage('Systems of selected Inspection Tasks are different');
        setDisplayInfoModal(true);
      }
    } else {
      setMessage('This operation can be performed only two rows at a time.');
      setDisplayInfoModal(true);
    }
  }

  const onSort = (event) => {
    if (event && inspectionTaskData && inspectionTaskData.length) {
      let sortDirection: string;
      if (event.sortOrder === 1)
        sortDirection = "ASC"
      else
        sortDirection = "DESC"
      setSortField(event.sortField)
      setSortOrder(sortDirection)
      let body = {
        uniqueCustomerId: selectedCustomerId,
        uniqueContractId: selectedContractId,
        isCustomerAll: isCustomerAll,
        isContractAll: isContractAll,
        active: toggleActiveTask,
        closed: toggleClosedTask,
        completed: toggleCompletedTask,
        dataFrom: selectedMonth.code,
      };
      controller.abort();
      const newController = new AbortController();
      setController(newController);
      dispatch(getInspectionTaskList(body, currentStart, keyWord, event.sortField, sortDirection, newController));
    }
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    setKeyWord(value);
    let body = {
      uniqueCustomerId: selectedCustomerId,
      uniqueContractId: selectedContractId,
      isCustomerAll: isCustomerAll,
      isContractAll: isContractAll,
      active: toggleActiveTask,
      closed: toggleClosedTask,
      completed: toggleCompletedTask,
      dataFrom: selectedMonth.code,
    };
    controller.abort();
    const newController = new AbortController();
    setController(newController);
    dispatch(getInspectionTaskList(body, 1, value, sortField, sortOrder, newController));
  };

  useEffect(() => {
    if (checkedDataForClose?.length != 0) {
      if (isTaskReadyToClose || inactivateFlag) {
        if (isTaskReadyToClose && checkedDataForClose?.isClosedTaskExistsForSystem === true) {
          setMessage("A Closed task already exist for the system");
          setDisplayInfoModal(true);
        }else if (checkedDataForClose?.isIncompleteTasksExistForSystem) {
          setMessage("Incomplete inspection task exist under this system, cannot be closed");
          setDisplayInfoModal(true);
        } else if (checkedDataForClose?.hasTechniciansJoinedInApp) {
          let technames = "";
          checkedDataForClose.techniciansName.map((item, currentIndex) => {
            if (currentIndex === 0) {
              technames += item;
            }
            else {
              technames += " ," + item;
            }
          })
          setMessage(`Do you want to quit all the tasks of the Technician(s) and ${inactivateFlag ? 'inactivate' : 'close'} the Inspection Task? Technicians connected to the Inspection Task -` + technames);
          setDisplayConfirmModal(true);
        }
        else {
          setMessage(`Are you sure you want to ${inactivateFlag ? 'inactivate' : 'close'} the selected Inspection Task?`);
          setDisplayConfirmModal(true);
        }
      }
    }
  }, [checkedDataForClose]);

  useEffect(() => {
    if (checkedDataForComplete?.length != 0 && isTaskReadyToComplete === true) {
      let pendingReportNames: any = [];
      if (checkedDataForComplete?.isReportsGenerated === false) {
        checkedDataForComplete.inspectionReports.map((item) => {
          pendingReportNames.push(item);
        })
        setMessage("All the Inspection Reports selected for this Task are not generated, Task cannot be completed");
        setIsTaskReadyToComplete(false);
        setPendingReportsList(pendingReportNames);
        setShowPendingReportModal(true);
      }
      else if (checkedDataForComplete?.isReportsGenerated === true) {
        if (checkedDataForComplete?.isFinalReportUploaded === false) {
          checkedDataForComplete.inspectionReports.map((item) => {
            pendingReportNames.push(item);
          })
          setMessage("Final reports for all the Inspection Reports selected for this Task are not uploaded, Task cannot be completed");
          setIsTaskReadyToComplete(false);
          setPendingReportsList(pendingReportNames);
          setShowPendingReportModal(true);
        }
        else {
          if (checkedDataForComplete?.isFinalReportUploaded === true) {
            setMessage(
              "Are you sure you want to complete the selected Inspection Task?"
            );
            setDisplayConfirmModal(true);
          }
        }
      }
    }
  }, [checkedDataForComplete]);

  useEffect(() => {
    if (isInspectionTaskDeleted.length > 0) {
      let listData = JSON.parse(JSON.stringify(taskDataInfoList))
      listData = listData.filter(
        (item: any) => item.uniqueInspectionTaskId !== isInspectionTaskDeleted
      );
      setTaskDataInfoList(listData);
      setMessage("Inspection Task deleted");
      setDisplayInfoModal(true);
      dispatch(setInspectionTaskDeletedData(''))
    }

  }, [isInspectionTaskDeleted])
  
  const onStatusChange = (e: { value: any; checked: boolean }) => {
    let selectedStatusCpy = [...selectedStatus];

    if (e.checked) selectedStatusCpy.push(e.value);
    else selectedStatusCpy.splice(selectedStatusCpy.indexOf(e.value), 1);

    setSelectedStatus(selectedStatusCpy);
    if (e.value === "Active") {
      setToggleActiveTask(!toggleActiveTask);
    } else if (e.value === "Closed") {
      setToggleClosedTask(!toggleClosedTask);
    } else if (e.value === "Completed") {
      setToggleCompletedTask(!toggleCompletedTask);
    };
    dispatch(setInspectionSearchId(""));
  };

  const onMonthChange = (e: { value: any }) => {
    setSelectedMonth(e.value);
    dispatch(setInspectionSearchId(""));
  };

  const handleRowSelect = useCallback((data: any) => { 
    if (data && data.length > 0) {
      //To resolve useEffect dependency
      setSelectedRows([...data]);
    }
    else {
      setSelectedRows(inspectionTaskData.length ? [inspectionTaskData[0]] : []);
    }
  }, []);

  const deleteIconClick = () => {
    setIsDelete(false);
    setIsTaskReadyToClose(false);
    setIsTaskReadyToComplete(false);
    setInactivateFlag(false)
    dispatch(setReportUploadedStatus([]))
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      if (selectedRows[0].status === "Active") {
        setMessage("Selected Inspection Task is active, cannot be deleted");
        setDisplayInfoModal(true);
      } else if (selectedRows[0].status === "Closed" || selectedRows[0].status === "Completed" || selectedRows[0].status === "Inactive") {
        setIsDelete(true);
        dispatch(checkFinalReportUploadStatus(selectedRows[0].uniqueInspectionTaskId));
      }
    }
  };

  const closeIconClick = () => {
    setIsTaskReadyToClose(false);
    setIsDelete(false);
    setIsTaskReadyToComplete(false);
    setInactivateFlag(false);
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      if (selectedRows[0].status === "Closed") {
        setMessage("Inspection Task is already closed");
        setDisplayInfoModal(true);
      } else {
        if (selectedRows[0]?.status === "Completed") {
          if (!showUI(userPrivileges, "SIETAP_INSPECTIONS_COMPLETEDTASKS_CLOSE")) {
            setMessage("Selected Inspection task is Completed, cannot be closed");
            setDisplayInfoModal(true);
            return false;
          }
        }
        let body = {
          uniqueSystemId: selectedRows[0].system.uniqueSystemId,
          status: selectedRows[0].status,
          uniqueContractId: selectedRows[0].uniqueContractId,
        };
        setIsTaskReadyToClose(true);
        dispatch(checkInspectionTaskForClose(selectedRows[0].uniqueInspectionTaskId, body,));
      }
    }
  };

  const completeIconClick = () => {
    setIsTaskReadyToComplete(false);
    setIsDelete(false);
    setIsTaskReadyToClose(false);
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      if (selectedRows[0].status === "Active") {
        setMessage("Selected Inspection Task is not closed, only closed Tasks can be set as completed");
        setDisplayInfoModal(true);
      }
      else if (selectedRows[0].status === "Completed") {
        setMessage("Inspection Task is already Completed");
        setDisplayInfoModal(true);
      }
      else {
        let body = {
          uniqueSystemId: selectedRows[0].system.uniqueSystemId,
          status: selectedRows[0].status,
          uniqueContractId: selectedRows[0].uniqueContractId,
        };
        setIsTaskReadyToComplete(true);
        dispatch(
          checkInspectionTaskForComplete(
            body,
            selectedRows[0].uniqueInspectionTaskId
          )
        );
      }
    }
  };

  const CustomHeaderCmp = () => {
    return (
      <div className="custom-header">
        <div className="row">
          <div className="col-3 box w-20 py-1 pr-0 pl-1">
            <div className="title">Site</div>
            <div className="sub-title" title={activeSite}>
              {activeSite}
            </div>
          </div>
          <div className="col-3 box w-22 py-1 pr-0 pl-1">
            <div className="title">Customer</div>
            <div className="sub-title" title={customerName}>
              {customerName}
            </div>
          </div>
          <div className="col-3 box w-30 py-1 pr-0 pl-1">
            <div className="title">Contract</div>
            <div className="sub-title" title={contractName}>
              {contractName}
            </div>
          </div>
          <div className="col-3 box w-28 py-1 pl-0 align-items-end pr-1">
            <div className="actions-section">
              <div className="status">
                <div className="field-checkbox">
                  <Checkbox
                    inputId={STATUS_MAP.Active}
                    name={STATUS_MAP.Active}
                    value={STATUS_MAP.Active}
                    onChange={onStatusChange}
                    checked={selectedStatus.indexOf(STATUS_MAP.Active) !== -1}
                  />
                  <label htmlFor={STATUS_MAP.Active}>{STATUS_MAP.Active}</label>
                </div>
                <div className="field-checkbox">
                  <Checkbox
                    inputId={STATUS_MAP.Closed}
                    name={STATUS_MAP.Closed}
                    value={STATUS_MAP.Closed}
                    onChange={onStatusChange}
                    checked={selectedStatus.indexOf(STATUS_MAP.Closed) !== -1}
                  />
                  <label htmlFor={STATUS_MAP.Closed}>{STATUS_MAP.Closed}</label>
                </div>
                <div className="field-checkbox">
                  <Checkbox
                    inputId={STATUS_MAP.Completed}
                    name={STATUS_MAP.Completed}
                    value={STATUS_MAP.Completed}
                    onChange={onStatusChange}
                    checked={
                      selectedStatus.indexOf(STATUS_MAP.Completed) !== -1
                    }
                  />
                  <label htmlFor={STATUS_MAP.Completed}>
                    {STATUS_MAP.Completed}
                  </label>
                </div>
              </div>
              <div className="months-list">
                <Dropdown
                  value={selectedMonth}
                  options={MONTHS_LIST}
                  onChange={onMonthChange}
                  optionLabel="name"
                  placeholder="Last 18 Months"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (comparisonData && comparisonData.equipmentDetails) {
      setComparisonModal(true);
    }
  }, [comparisonData])

  const onConfirmAction = () => {
    if (isDelete === true) {
      setIsDelete(false);
      setDisplayConfirmModal(false);
      const uniqueInspectionTaskId = selectedRows[0].uniqueInspectionTaskId;
      if (selectedRows.length === 1) {
        dispatch(
          deleteInspectionTask({
            uniqueInspectionTaskId,
            setSelectedRows,
            setMessage,
            setDisplayInfoModal,
          })
        );
      }
    }
    else if (isTaskReadyToClose === true) {
      setDisplayConfirmModal(false);
      let body = {
        uniqueContractId: selectedRows[0].uniqueContractId,
      };
      dispatch(
        onCloseInspectionTask(
          body,
          selectedRows[0].uniqueInspectionTaskId,
          setMessage,
          setDisplayInfoModal,
          setAdditionalMessage,
        )
      );
      setTimeout(() => {
        selectedRows[0].status = "Closed"
      }, 200)
    }
    else if (isTaskReadyToComplete === true) {
      setDisplayConfirmModal(false);
      let body = {
        uniqueContractId: selectedRows[0].uniqueContractId,
      };
      dispatch(
        onCompleteInspectionTask(
          body,
          selectedRows[0].uniqueInspectionTaskId,
          setMessage,
          setDisplayInfoModal,
        )
      );
    } else if (inactivateFlag) {
      setDisplayConfirmModal(false);
      let newDate = new Date();
      let updatedDate = moment(new Date(newDate)).format("YYYY-MM-DD").toString();
      dispatch(inActivateInspectionTask(selectedRows[0], setSelectedRows, setMessage, setDisplayInfoModal, updatedDate));
    } else if (isFontChange) {
      setIsFontChange(false);
      setDisplayConfirmModal(false);
      let body = {
        fontClass: fontClass
      }
      dispatch(setDefaultFontSize(body, setMessage, setDisplayInfoModal))
    } else if (isImportPrevious) {
      setIsImportPrevious(false);
      setDisplayConfirmModal(false)
      setShowImportPreviousTaskDetails(true)
    }
  };

  const onCancel = () => {
    if (isFontChange) {
      setIsFontChange(false);
    }
    else if (isImportPrevious) {
      setIsImportPrevious(false);
    }
    if(inactivateFlag){
      setInactivateFlag(false);
    }
  }

  const onInactivateClick = (selectedRows) => {
    if (selectedRows?.length === 1) {
      if (selectedRows[0]?.status === 'Inactive') {
        setMessage('Inspection Task is already inactive');
        setDisplayInfoModal(true);
      } else if (selectedRows[0]?.status === 'Completed') {
        setMessage('Inspection Task is completed, cannot be Inactivated');
        setDisplayInfoModal(true);
      } else {
        setIsTaskReadyToClose(false);
        setInactivateFlag(true);
        let body = {
          status: "closed",
          uniqueContractId: selectedRows[0]?.uniqueContractId,
          uniqueSystemId: selectedRows[0]?.system?.uniqueSystemId
        };
        dispatch(checkInspectionTaskForClose(selectedRows[0].uniqueInspectionTaskId, body));
      }
    } else {
      setMessage('This operation can be performed only one row at a time.');
      setDisplayInfoModal(true);
    }
  }

  const onQuitTaskClick = (selectedRows) => {
    if (selectedRows && selectedRows.length) {
      if (selectedRows?.length === 1) {
        let selectedTaskData = selectedRows[0];
        if (selectedTaskData.status === "Active") {
          setShowQuitTask(true);
        } else {
          setMessage('Selected Inspection Task is not Active');
          setDisplayInfoModal(true);
        }
      } else {
        setMessage('This operation can be performed only one row at a time.');
        setDisplayInfoModal(true);
      }
    }
  }

  const onReportsSelectedClick = (selectedRows) => {
    if (selectedRows?.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else {
      dispatch(getSelectedReportList(selectedRows[0]?.uniqueInspectionTaskId));
      setIsReportSelected(true);
      setShowReportsModal(true);
    }
  };

  useEffect(() => {
    if (selectedReportList != "") {
      setSelectedReports(selectedReportList.reportDetails);
    }
  }, [selectedReportList, isReportSelected]);

  const saveReportClick = (selections) => {
    let selectedReportId: any = [];
    selections.map((item: any) => {
      return selectedReportId.push(item.inspectionReportId.toString());
    });
    dispatch(
      onUpdateReportSelected(
        selectedReportId,
        selectedRows[0].uniqueInspectionTaskId,
        setMessage,
        setDisplayInfoModal,
        setShowReportsModal,
        selections,
        setSelectedReports,
      )
    );
  };

  const editIconClick = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows[0]?.system?.systemStatus === "Closed") {
      setMessage(
        "System of selected Inspection task is closed, cannot be edited"
      );
      setDisplayInfoModal(true);
    } else {
      if (selectedRows[0]?.isRecentTask) {
        setShowEditModal(true);
      }
      else if (!selectedRows[0]?.isRecentTask) {
        if (!showUI(userPrivileges, "SIETAP_INSPECTIONS_COMPLETEDTASKS_EDIT")) {
          setShowEditModal(false);
          setMessage("Selected Inspection Task is not a recently added Task. Only the recently added Inspection Tasks can be edited");
          setDisplayInfoModal(true);
        }
        else {
          setShowEditModal(true);
        }
      }
    }
  };

  const onAddInspectionTaskClick = (submitData: any) => {
    setIsAddSubmit(true);
    const pageDetailsInfo = {
      uniqueCustomerId: selectedCustomerId,
      uniqueContractId: selectedContractId,
      isCustomerAll: isCustomerAll,
      isContractAll: isContractAll,
      active: toggleActiveTask,
      closed: toggleClosedTask,
      completed: toggleCompletedTask,
      dataFrom: selectedMonth.code,
      sortField: sortField,
      sortOrder: sortOrder,
      keyWord: keyWord,
      currentStart: currentStart
    };
    const body = { ...submitData, pageInfo: pageDetailsInfo };
    dispatch(
      onAddInspectionTask(
        body,
        setMessage,
        setDisplayInfoModal,
        setShowAddModal
      )
    );
  };

  useEffect(() => {
    if (newInspectionTask && isAddSubmit && systemInUse?.length != 0) {
      if (systemInUse?.isSiemens) {
        if (newInspectionTask.isParsed) {
          setMessage("Inspection Task added");
          dispatch(setSystemInUse([]));
        } else {
          setMessage("Inspection Task added. Sending Equipment list to the Server. For large systems, it will take some time..");
          dispatch(setSystemInUse([]));
        }
      } else {
        setMessage("Inspection Task added");
        dispatch(setSystemInUse([]));
      }
      let newData = JSON.parse(JSON.stringify(newInspectionTask));
      newData.plannedEndDate = moment(newData.plannedEndDate).format("YYYY-MM-DD").toString()
      newData.plannedStartDate = moment(newData.plannedStartDate).format("YYYY-MM-DD").toString()
      setDisplayInfoModal(true);
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      if (inspectionTask.length === 0) {
        inspectionTask.push(newData);
        const pagination = {
          currentLength: pageDetails?.currentLength + 1,
          currentStart: pageDetails?.currentStart,
          pageCount: pageDetails?.pageCount + 1,
          totalCount: pageDetails?.totalCount
        }
        setPageDetails(pagination);
      }
      else {
        inspectionTask.unshift(newData);
      }
      setInspectionTaskData(inspectionTask);    
      setSelectedRows((inspectionTask && inspectionTask.length) ? [inspectionTask[0]] : []);
      setIsAddSubmit(false);
      setDisableIcons(false)
    }
  }, [newInspectionTask, systemInUse, isAddSubmit, inspectionTaskData]);

  const onEditInspectionTaskClick = (submitData: any) => {
    setIsEditSubmit(true);
    dispatch(setUpdatedInspectionData(""))
    dispatch(
      onEditInspectionTask(
        submitData,
        selectedRows[0].uniqueInspectionTaskId,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal
      )
    );
  };

  useEffect(() => {
    if (updatedInspectionTask && updatedInspectionTask?.uniqueInspectionTaskId === selectedRows[0]?.uniqueInspectionTaskId && isEditSubmit && systemInUse?.length != 0) {
      if (systemInUse?.isSiemens) {
        if (updatedInspectionTask.isParsed) {
          setMessage("Inspection Task updated");
          dispatch(setSystemInUse([]))
        } else {
          setMessage("Inspection Task updated. Sending Equipment list to the Server. For large systems, it will take some time..");
          dispatch(setSystemInUse([]))
        }
      } else {
        setMessage("Inspection Task Updated");
        dispatch(setSystemInUse([]))
      }
      setDisplayInfoModal(true);
      let updatedData = JSON.parse(JSON.stringify(updatedInspectionTask));
      updatedData.plannedEndDate = moment(updatedData.plannedEndDate).format("YYYY-MM-DD").toString()
      updatedData.plannedStartDate = moment(updatedData.plannedStartDate).format("YYYY-MM-DD").toString()
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      let currentIndex = inspectionTask.findIndex((item: any) => item.uniqueInspectionTaskId === selectedRows[0].uniqueInspectionTaskId);
      const newInspectionList: any = inspectionTask.filter((item: any) => item.uniqueInspectionTaskId !== selectedRows[0].uniqueInspectionTaskId);
      newInspectionList.splice(currentIndex, 0, updatedData);
      setInspectionTaskData(newInspectionList);
      setIsEditSubmit(false);   
      setSelectedRows([updatedData])
    } else if (updatedInspectionTask && updatedInspectionTask?.uniqueInspectionTaskId === inspectionRowData?.uniqueInspectionTaskId && isRowEdit) {

      let updatedData = JSON.parse(JSON.stringify(updatedInspectionTask));
      updatedData.plannedEndDate = moment(updatedData.plannedEndDate).format("YYYY-MM-DD").toString()
      updatedData.plannedStartDate = moment(updatedData.plannedStartDate).format("YYYY-MM-DD").toString()
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      let currentIndex = inspectionTask.findIndex((item: any) => item.uniqueInspectionTaskId === inspectionRowData?.uniqueInspectionTaskId);
      const newInspectionList: any = inspectionTask.filter((item: any) => item.uniqueInspectionTaskId !== inspectionRowData?.uniqueInspectionTaskId);
      newInspectionList.splice(currentIndex, 0, updatedData);
      setInspectionTaskData(newInspectionList);
      setMessage("Inspection Task updated");
      setSelectedRows([updatedData])
      setDisplayInfoModal(true);
      setIsRowEdit(false);
    }
  }, [
    updatedInspectionTask,
    systemInUse,
    isEditSubmit,
    inspectionTaskData,
    selectedRows,
  ]);

  useEffect(() => {
    if (updatedInspectionTask && updatedInspectionTask !== "" && isTaskReadyToClose === true) {
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      inspectionTask.map((item) => {
        if (item.uniqueInspectionTaskId === updatedInspectionTask.uniqueInspectionTaskId) {
          item.status = updatedInspectionTask.inspectionTaskStatus;
          let newDate = new Date();
          item.statusUpdateDate = moment(new Date(newDate)).format("YYYY-MM-DD").toString();
        }
      })
      setInspectionTaskData(inspectionTask);
      setIsTaskReadyToClose(false);
    }
  }, [updatedInspectionTask])

  useEffect(() => {
    if (updatedInspectionTask && updatedInspectionTask !== "" && isTaskReadyToComplete === true) {
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      inspectionTask.map((item) => {
        if (item.uniqueInspectionTaskId === updatedInspectionTask.uniqueInspectionTaskId) {
          item.status = updatedInspectionTask.inspectionTaskStatus;
          let newDate = new Date();
          item.statusUpdateDate = moment(new Date(newDate)).format("YYYY-MM-DD").toString();
        }
      })
      setInspectionTaskData(inspectionTask);
      setIsTaskReadyToComplete(false);
    }

  }, [updatedInspectionTask])

  useEffect(() => {
    if (inspectionTaskStatus && inactivateFlag === true) {
      let inspectionTask = JSON.parse(JSON.stringify(inspectionTaskData));
      inspectionTask.map((item) => {
        if (item.uniqueInspectionTaskId === inspectionTaskStatus.uniqueInspectionTaskId) {
          item.status = inspectionTaskStatus.status;
          let newDate = new Date();
          item.statusUpdateDate = moment(new Date(newDate)).format("YYYY-MM-DD").toString();
        }
      })
      setInspectionTaskData(inspectionTask);
      setInactivateFlag(false);
    }

  }, [inspectionTaskStatus])

  const onDrawingsClicked = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else {
      setShowDrawingList(true);
    }
  };
  const handleFontChange = (e: any) => {
    if (Array.from(e?.target?.classList).includes("text")) {
      let className = "";
      className = (e?.target?.classList.value).toString();
      if (className.includes("text small")) {
        setFontClass("f-small");
        setSmallFont(true);
        setMediumFont(false);
        setLargeFont(false);
      } else if (className.includes("text medium")) {
        setFontClass("f-medium");
        setSmallFont(false);
        setMediumFont(true);
        setLargeFont(false);
      } else if (className.includes("text large")) {
        setFontClass("f-large");
        setSmallFont(false);
        setMediumFont(false);
        setLargeFont(true);
      }
      setMessage("Do you want to set this font size as your default setting?");
      setIsFontChange(true);
      setDisplayConfirmModal(true);
    }
  };
  const handleTableColumns = useCallback((data: any) => {
    setTableColumns([...data]);
  }, [selectedContractDetails]);

  const onRowEditClick = (rowData) => {
    let isEditable = true;
    if (rowData) {
      if (rowData?.system?.systemStatus === "Closed") {
        setMessage(
          "System of selected Inspection task is closed, cannot be edited"
        );
        setDisplayInfoModal(true);
        isEditable = false;
      } else {
        if (rowData?.isRecentTask) {
          isEditable = true;
        }
        else if (!rowData?.isRecentTask) {
          if (!showUI(userPrivileges, "SIETAP_INSPECTIONS_COMPLETEDTASKS_EDIT")) {
            isEditable = false;
            setMessage("Selected Inspection Task is not a recently added Task. Only the recently added Inspection Tasks can be edited");
            setDisplayInfoModal(true);
            return false;
          }
          else {
            isEditable = true;
          }
        }
        setInspectionRowData(rowData)
        let body = {
          UniqueContractId: rowData?.uniqueContractId,
        }
        dispatch(getTechnicians(body))
        dispatch(getSelectedReportList(rowData?.uniqueInspectionTaskId))
        dispatch(getContractDetails(rowData?.uniqueContractId))
        dispatch(checkSystemInUse(rowData?.system?.uniqueSystemId));
      }
    }
    return isEditable;
  }

  const onRowEditValidation = (rowData: any) => {
    
    let value = true;
    if (countryId === 220 && rowData.contractNumber.startsWith("44OP") ||
      countryId === 220 && rowData.contractNumber.startsWith("44OC")) {
      if (!rowData.inspectionTaskName || !rowData.qtyOfTech || !rowData.leadTechnician) {
        value = false;
      }
    } else if (!rowData.inspectionTaskName || !rowData.notificationNumber || !rowData.qtyOfTech ||
      !rowData.leadTechnician || !isPatternValid(rowData.notificationNumber)) {
      value = false;
    }
    else if (new Date(rowData.plannedStartDate) > new Date(rowData.plannedEndDate)) {
      setDisplayInfoModal(true)
      setMessage("Planned End Date should be greater than Planned Start Date")
      value = false;
    } else if (new Date(rowData.plannedStartDate) < new Date(contractDetails?.startDate) || new Date(rowData?.plannedEndDate) > new Date(contractDetails?.endDate)) {
      setMessage("Planned Start and End Date of the Inspection task is not within the Start and End Date of Contract.");
      setDisplayInfoModal(true);
    } 
    if ((rowData.qtyOfTech < 1 || rowData.qtyOfTech > 99)) {
      setMessage("Enter a value between 1 and 99 in Qty of Techs");
      setDisplayInfoModal(true);
      value = false;
    }
    if (systemInUse && rowData.status === "Completed") {
      if (systemInUse.isActiveTaskExists) {
        setMessage("An active Inspection Task already exists for this System, cannot activate the selected Task");
        setDisplayInfoModal(true);
        value = false;
      } else if (!systemInUse.isInspectionTaskCompleted) {
        setMessage("Inspection Task not completed for this System, cannot edit the active Inspection Task");
        setDisplayInfoModal(true);
        value = false;
      } else if (!systemInUse.isXMLFileUploaded && systemInUse.isSiemens) {
        setMessage("XML File is not uploaded for the System, cannot activate the Inspection Task");
        setDisplayInfoModal(true);
        value = false;
      }
    }
    return value;
  }

  const isPatternValid = (notificationNumber) => {
    if (!notificationNumber) {
      return true;
    }
    if (countryId === 38) {
      return /^((600)[0-9]{6}|[5][0-9]{9})$/.test(notificationNumber);
    } else if (countryId === 220) {
      return /^[5][0-9]{9}$/.test(notificationNumber);
    }
  }

  const onInspectionTaskRowEdit = (submitData: any) => {
    let uniqueInspectionTaskId: any;
    uniqueInspectionTaskId = submitData.uniqueInspectionTaskId;
    submitData.uniqueSystemId = submitData?.system?.uniqueSystemId;
    submitData.plannedEndDate = moment(submitData.plannedEndDate).format("YYYY-MM-DD").toString()
    submitData.plannedStartDate = moment(submitData.plannedStartDate).format("YYYY-MM-DD").toString()
    submitData.activeSiteId= loggedInUserDetails?.userDefaults?.uniqueSiteId;
    let technician: any;
    technician = technicianData.find((item: any) => item.formattedLeadTechnicianName === submitData?.leadTechnician)
    submitData.uniqueTechnicianId = technician?.uniqueUserId;
    submitData.quantityOfTech = submitData.qtyOfTech;
    let reportList = JSON.parse(JSON.stringify(selectedReportList?.reportDetails))
    let selectedReportId: any = [];
    if (reportList) {
      reportList.map((item: any) => {
        return selectedReportId.push(item.inspectionReportId)
      })
    }
    submitData.reportIds = selectedReportId
    submitData.inspectionTaskName = submitData.inspectionTaskName?.trim();
    if (submitData.status === "Active") {
      submitData.activate = true;
    } else {
      submitData.activate = false;
    }
    setIsRowEdit(true);
    dispatch(
      onEditInspectionTask(
        submitData,
        uniqueInspectionTaskId,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal
      )
    );
  };

  const onShowEquipmentListModel = () => {   
    if (selectedRows?.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else {
      dispatch(getSelectedReportList(selectedRows[0]?.uniqueInspectionTaskId));
      setShowEquipmentListModal(true);
    }
  }

  const getInspectionTechnicians = (taskData: any) => {
    dispatch(
      getInspectionTaskTechnicians(
        taskData.uniqueInspectionTaskId,
        setMessage,
        setDisplayInfoModal,
        setShowQuitTask
      )
    );

  }



  const onQuitTask = (submitData: any) => {
    dispatch(
      quitTask(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowQuitTask
      )
    )
  };

  const checkImportPreviousTaskDetails = (selectedRows) => {
    if (selectedRows?.length < 1) {
      setMessage("Select an Inspection Task");
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else {
      if (selectedRows[0]?.status === "Active") {
        setIsImportPrevious(true)
        dispatch(
          getImportPreviousTaskDetails(
            selectedRows[0]?.system?.uniqueSystemId,
            setMessage,
            setDisplayInfoModal,
            setShowImportPreviousTaskDetails,
            setPreviousTaskDetails,
            setDisplayConfirmModal
          )
        )
      } else {
        setMessage("Inspection Task is not Active, cannot import previous Inspection Task result");
        setDisplayInfoModal(true);
      }
    }
  }

  const onComparisonPageChanged = (options: any, sortData: any) => {
    if (selectedRows && selectedRows.length && (options !== comparisonPageIndex)) {
      setComparisonPageIndex(options);
      let currentStart = (options * comparisonData?.pageDetails?.currentLength) + 1
      setComparisonCurrentStart(currentStart);
      let body = {
        uniqueInspectionTaskId1: selectedRows[0]?.uniqueInspectionTaskId,
        uniqueInspectionTaskId2: selectedRows[1]?.uniqueInspectionTaskId,
        systemid: selectedRows[0]?.system?.uniqueSystemId
      };
      dispatch(compareTasks(body, currentStart, sortData, setComparisonData, setMessage, setDisplayInfoModal));
    }
  };

  const onComparisonSort = (sortData: any) => {
    if (selectedRows && selectedRows.length) {
      let body = {
        uniqueInspectionTaskId1: selectedRows[0]?.uniqueInspectionTaskId,
        uniqueInspectionTaskId2: selectedRows[1]?.uniqueInspectionTaskId,
        systemid: selectedRows[0]?.system?.uniqueSystemId
      };
      dispatch(compareTasks(body, comparisonCurrentStart, sortData, setComparisonData, setMessage, setDisplayInfoModal));
    }
  };

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const getSelectedInspectionTaskIds = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueInspectionTaskId);
    });
    return params;
  };

  const getContractStatus = () => {
    let contractFlag = true
    if (!isCustomerAll) {
      if (isContractAll) {
        contractFlag = true
      } else {
        contractFlag = false
      }
    };
    return contractFlag;
  };

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        uniqueCustomerId: isCustomerAll ? '' : selectedCustomerId,
        uniqueContractId: getContractStatus() ? '' : selectedContractId,
        uniqueInspectionTaskId: "",
        statusId: 1,
        recentOrAll: selectedMonth.code,
        uniqueSystemId: "",
        allCustomers: isCustomerAll,
        allContracts: getContractStatus(),
        selectedIds: "",
        sortColumn: sortField,
        sortOrder: sortOrder,
        search: keyWord,
        active: toggleActiveTask,
        closed: toggleClosedTask,
        completed: toggleCompletedTask,
      },
      exportType: "Inspection Task Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Inspection Tasks"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        uniqueCustomerId: isCustomerAll ? '' : selectedCustomerId,
        uniqueContractId: getContractStatus() ? '' : selectedContractId,
        uniqueInspectionTaskId: "",
        statusId: 1,
        recentOrAll: selectedMonth.code,
        uniqueSystemId: "",
        allCustomers: isCustomerAll,
        sortColumn: sortField,
        sortOrder: sortOrder,
        search: keyWord,
        active: toggleActiveTask,
        closed: toggleClosedTask,
        completed: toggleCompletedTask,
        allContracts: getContractStatus(),
        selectedIds: getSelectedInspectionTaskIds()
      },
      exportType: "Inspection Task Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Inspection Tasks"));
  };

  const refreshGrid = () => {
    setGridReadyToRefresh(true);
  }

  const resetInspectionTaskGridAPICall = (newController)=>{
    let body = {
      uniqueCustomerId: selectedCustomerId,
      uniqueContractId: selectedContractId,
      isCustomerAll: isCustomerAll,
      isContractAll: isContractAll,
      active: false,
      closed: false,
      completed: false,
      dataFrom: selectedMonth.code,
    };
    setInspectionTaskData([]);
    setSelectedRows([]);
    dispatch(getInspectionTaskList(body, 1, keyWord, sortField, sortOrder, newController));
  }


  const reportHeaderTemplate = (

    <div className="search-section d-flex align-items-center justify-content-between">
      <span className="p-input-icon-left table-search mr-4">
        <InputText
          type="search"
          value={keyWord || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search System / Task Name"
        />
        {!keyWord && <i className="pi pi-search" title="Search Button" />}
      </span>
      <div
        className={classNames("table-config", {
          "custom-align": false,
        })}
      >
        <div
          className="font-adjust-section"
          onClick={(e) => handleFontChange(e)}
        >
          <span className={`text small ${isSmallFont ? "active" : ""}`}>A</span>
          <span className={`text medium ${isMediumFont ? "active" : ""}`}>A</span>
          <span className={`text large ${isLargeFont ? "active" : ""}`}>A</span>
        </div>


        <div className="action-btns">
          {showAddIcon && isActiveContract && showUI(userPrivileges, "SIETAP_FS_INSPECTIONS_MANAGE") && (
            <Button
              className="button-icon"
              tooltip="Add"
              tooltipOptions={{ position: "top" }}
              onClick={() => {
                dispatch(setNewInspectionData(""))
                setShowAddModal(true)
              }}
              disabled={customerName === "None" || contractName === "None" ? true : false}
            >
              <BsPlusLg className="icon" />
            </Button>
          )}
          {showEditIcon && showUI(userPrivileges, "SIETAP_FS_INSPECTIONS_MANAGE") && (
            <Button
              className="button-icon"
              tooltip="Edit"
              tooltipOptions={{ position: "top" }}
              onClick={editIconClick}
              disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
            >
              <BsFillPencilFill className="icon" />
            </Button>
          )}

          {((showUI(userPrivileges, 'SIETAP_FS_INSPECTIONS_MANAGE_DELETE')) && showUI(userPrivileges, "SIETAP_FS_INSPECTIONS_MANAGE") && showDeleteIcon) && (
            <Button
              className="button-icon"
              tooltip="Delete"
              tooltipOptions={{ position: "top" }}
              onClick={deleteIconClick}
              disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
            >
              <BsTrashFill className="icon" />
            </Button>
          )}


          <Button
            className="button-icon"
            tooltip="Inspection Details"
            tooltipOptions={{ position: "top" }}
            onClick={onShowEquipmentListModel}
            disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
          >
            <BsGridFill className="icon" />
          </Button>
          {showUI(userPrivileges, 'SIETAP_INSPECTIONS_CLOSE') &&
            <Button
              className="button-icon"
              tooltip="Close"
              tooltipOptions={{ position: "top" }}
              onClick={closeIconClick}
              disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
            >
              <BsXCircleFill className="icon" />
            </Button>
          }
          {showUI(userPrivileges, 'SIETAP_INSPECTIONS_COMPLETE') &&
            <Button
              className="button-icon"
              tooltip="Complete"
              tooltipOptions={{ position: "top" }}
              onClick={completeIconClick}
              disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
            >
              <BsCheckCircleFill className="icon" />
            </Button>
          }
          <Button
            className="button-icon"
            tooltip="Download"
            tooltipOptions={{ position: "top" }}
            onClick={(e) => downloadRef.current?.toggle(e)}
            disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
          >
            <FaDownload className="icon" />
            <FaChevronDown className="p-button-text caret-icon" />
          </Button>
          <Button
            className="button-icon"
            tooltip="More"
            tooltipOptions={{ position: "top" }}
            onClick={(e) => settingRef.current?.toggle(e)}
            disabled={customerName === "None" || contractName === "None" || disableIcons ? true : false}
          >
            <FaCog className="icon" />
            <FaChevronDown className="p-button-text caret-icon" />
          </Button>

        </div>
      </div>
    </div>
  );
  return (
    <div className="inspection-tasks d-flex">
      <section
        className={`${filterExpanded ? "expanded" : ""
          } inspection-tasks-filter d-flex flex-column align-items-center`}
      >
        <Filters
          filterExpanded={filterExpanded}
          setFilterExpanded={setFilterExpanded}
          inspectionTasksFilter
          setShowAddInspectionTaskModal={setShowAddModal}
        />
      </section>
      <InspectionTableData getTableColumns={handleTableColumns} selectedRow={inspectionRowData} customerAllChecked={customerAllChecked} contractAllChecked={contractAllChecked} contractNumber={selectedContractDetails?.contractNumber} />
      <section
        className={`${filterExpanded ? "expanded" : ""} inspection-tasks-table ${fontClass}`}
      >
        {inspectionTaskCols.length > 0 &&
          <DataTableComponent
            rows={inspectionTaskData}
            cols={inspectionTaskCols}
            title={`Inspection Tasks`}
            paginator
            dataKeyId="uniqueInspectionTaskId"
            pageDetails={pageDetails}
            pageIndex={pageIndex}
            onPageChanged={onPageChanged}
            headerTemplate={reportHeaderTemplate}
            showCustomHeader={true}
            getSelectedRowData={handleRowSelect}
            CustomHeaderCmp={CustomHeaderCmp}
            onSortData={onSort}
            showroweditor={showUI(userPrivileges, 'SIETAP_FS_INSPECTIONS_MANAGE')}
            onRowEditClick={onRowEditClick}
            onRowEditValidation={onRowEditValidation}
            onSaveRowEdit={onInspectionTaskRowEdit}
            settingRef={settingRef}
            settingListItems={settingListItems}
          isTemplateMode={true}
          isTemplateFromParent={true}
          templateFromParent={inspectionGridColumnTemplate}
          />}
      </section>
      {showAddModal && (
        <AddInspectionTask
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          uniqueContractId={selectedContractId}
          uniqueCustomerId={selectedCustomerId}
          customerName={customerName}
          contractName={contractName}
          onAddInspectionTaskClick={(data) => onAddInspectionTaskClick(data)}
        />
      )}

      {showEquipmentListModal &&
        <InspectionDetails
          selectedTask={selectedRows[0]}
          showModal={showEquipmentListModal}
          setShowModal={setShowEquipmentListModal}
          selectedReports={selectedReports}
          setShowReportModal={setShowReportsModal}
          taskWithoutBranchOffice={false}
          inspectionTaskGridRefresh={refreshGrid}
        />
      }
      {showEditModal && (
        <EditInspectionTask
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          selectedRows={selectedRows}
          uniqueContractId={selectedRows[0]?.uniqueContractId}
          uniqueCustomerId={selectedRows[0]?.uniqueCustomerId}
          customerName={selectedRows[0]?.customerName}
          contractName={selectedRows[0]?.contractName}
          onEditInspectionTaskClick={(data) => onEditInspectionTaskClick(data)}
        />
      )}
      {showDrawingList && (
        <DrawingList
          showModal={showDrawingList}
          setShowModal={setShowDrawingList}
          selectedRows={selectedRows}
        />
      )}
      {showReportsModal && (
        <InspectionReports
          showInfoCard
          showModal={showReportsModal}
          setShowModal={setShowReportsModal}
          selectedReports={selectedReports}
          isAdd={false}
          selectedRows={selectedRows}
          saveReportClick={saveReportClick}
        />
      )}
      {showPendingReportModal && (
        <PendingReports
          showModal={showPendingReportModal}
          setShowModal={setShowPendingReportModal}
          pendingReportNames={pendingReportList}
          message = {message}
        />
      )}
      {comparisonModal && (
        <CompareInspectionTasks
          showModal={comparisonModal}
          setShowModal={setComparisonModal}
          tableData={comparisonData}
          pageIndex={comparisonPageIndex}
          onPageChanged={onComparisonPageChanged}
          onSort={(data) => onComparisonSort(data)}
          inspectionTasksSelected={selectedRows}
        />
      )}
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
        additionalMessage={additionalMessage}
        setAdditionalMessage={setAdditionalMessage}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={onConfirmAction}
        cancelAction={onCancel}
        message={message}
      />
      {showDrawingModal && (
        <InspectionDrawingsModal
          closeModal={() => {
            setShowDrawingModal(false);
          }}
        />
      )}
      {showImportPreviousTaskDetails && <ImportPreviousTaskDetails
        showModal={showImportPreviousTaskDetails}
        setShowModal={setShowImportPreviousTaskDetails}
        displayColumns={inspectionTaskCols}
        setdisplayColumns={setTableColumns}
        setMessage={setMessage}
        setDisplayInfoModal={setDisplayInfoModal}
        previousTaskDetails={previousTaskDetails}
        selectedRows={selectedRows}
        setShowImportPreviousTaskDetails={setShowImportPreviousTaskDetails}
      />}
      {showQuitTask && <QuitTask
        showModal={showQuitTask}
        setShowModal={setShowQuitTask}
        selectedRows={selectedRows}
        getInspectionTechnicians={(data) => getInspectionTechnicians(data)}
        onQuitTask={(data) => onQuitTask(data)}
      />}
      <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
          <li onClick={onExportHighlightedClick}>Export Highlighted</li>
        </ul>
      </PopOver>
      {showReportModal ? (
        <InspectionTaskReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          sortByColumns={tableColumns}
          searchText={keyWord}
          active={toggleActiveTask}
          closed={toggleClosedTask}
          completed={toggleCompletedTask}
          dataFrom={selectedMonth.code}
          sortField={sortField}
          sortOrder={sortOrder} />
      ) : null}
      {showEquipmentSummaryModal ?
        <EquipmentSummary
          showModal={showEquipmentSummaryModal}
          setShowModal={setShowEquipmentSummaryModal}
          selectedRows={selectedRows} /> : null}
    </div>
  );
};

export default memo(InspectionTasks);
