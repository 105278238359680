import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from 'primereact/overlaypanel';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { BsCloud, BsExclamationCircleFill, BsFillEyeFill, BsFillPencilFill, BsPaperclip, BsChatLeftText, BsPencil, BsPlusLg, BsSearch, BsSubtract, BsTrashFill } from "react-icons/bs";
import { FaChevronDown, FaCog, FaDownload, FaSave, FaSlash, FaBackward } from "react-icons/fa";

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiDotsVertical, BiRectangle, BiMove, BiLayer } from "react-icons/bi";
//components
import DataTableComponent from "components/Table/DataTable";
import PopOver from 'components/common/PopOver/PopOver';
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

import AddAsBuilts from "./ManageDrawings/AddAsBuilts";
import EditAsBuilts from "./ManageDrawings/EditAsBuilts";
import ReviseASbuilts from './ManageDrawings/Revise';
import ReplaceASbuilts from './ManageDrawings/Replace';
import CommonDialog from "./ManageDrawings/MangeDialogModel/CommonDialog";
import AsbuiltsDrawing from "./ManageDrawings/AsbuiltsDrawing";
import AddBuildings from "pages/Sites/ManageBuildings/AddBuildings";
import PdfDrawingViewer from "./ManageDrawings/PdfDrawingViewer";


import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { deleteDrawing, downloadDrawing, getBuildingList, getDrawingList, onAddDrawing, onDrawingReplace, onDrawingRevise, onUpdateDrawing, setBuildingList, setDrawingList, setNewDrawing, setReplacedDrawing, setRevisedDrawing, setUpdatedDrawing,insertDrawingmarkup, setDeletedMarkupData,setAddMarkup,loadXmldata,setXmlData, setPreviousDrawing } from "redux/pages/AsBuilts/AsBuiltsSlice";
import { onAddBuilding, setNewBuilding} from "redux/pages/Administration/Building/buildingSlice";


import { IoEllipseOutline } from "react-icons/io5";
import {  setProgressFlag } from "redux/common/commonSlice";

interface Props {}

const AsBuilts: React.FC<Props> = (props) => {

    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const buildingList = useAppSelector((state) => state.asbuilts.buildingList);
    const drawingList = useAppSelector((state) => state.asbuilts.drawingList);
    const drawingPageDetails = useAppSelector((state) => state.asbuilts.drawingPageDetails);
    const newDrawing = useAppSelector((state) => state.asbuilts.newDrawing);
    const updatedDrawing = useAppSelector((state) => state.asbuilts.updatedDrawing);
    const previousDrawing = useAppSelector((state) => state.asbuilts.previousDrawing);
    const revisedDrawing = useAppSelector((state) => state.asbuilts.revisedDrawing);
    const replacedDrawing = useAppSelector((state) => state.asbuilts.replacedDrawing);
    const newBuilding = useAppSelector((state) => state.building.newBuilding);
  
    const [pageIndex, setPageIndex] = useState(0);
    const [leftToggle, setLeftToggle] = useState(false);  
    const [showReviseAsbuilts, setShowReviseAsbuilts] = useState<boolean>(false);
    const [showReplaceAsbuilts, setShowReplaceAsbuilts] = useState<boolean>(false);
    const [rightToggle, setRightToggle] = useState(true);
    const [showAddAsBuilts, setShowAddAsBuilts] = useState<boolean>(false);
    const [showEditAsBuilts, setShowEditAsBuilts] = useState<boolean>(false);
    const [showListMarkups, setShowListMarkups] = useState<boolean>(false);
    const [showListRevisions, setShowListRevisions] = useState<boolean>(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [buildingLookup, setBuildngLookup] = useState<any>();
    const [selectedBuilding, setSelectedBuilding] = useState<any>();
    const [selectedSite, setSelectedSite] = useState<any>();
    const [drawingData, setDrawingData] = useState([]);
    const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');
    const [showDrawing, setshowDrawing] = useState(false);
    const [showMarkupList,setShowMarkupList] = useState<boolean>(false);
    const [toolData, setToolData] = useState<any>([]);
    const [settingListItems, setSettingListItems] = useState<any>()
    const [showAddBuildingsModal, setShowAddBuildingsModal] = useState<boolean>(false);
    const settingRef = React.createRef<OverlayPanel>();
    const downloadRef = React.createRef<OverlayPanel>();
   
    
    
    const [drawingresize, setDrawingresize] = useState(false);
    const [DrawingObj, setDrawingObj] = useState<any>([])
    const [isDrawingOpen,setisDrawingOpen]=useState(false);
    const [selectedDrawing,setSelectedDrawing]=useState<any>([]);
    const [deleteMarkup, setDeleteMarkup] = useState("")
    const [disableAddBtn, setDisabeAddBtn] = useState(true);
    const [btnDisable, setBtnDisable] = useState(true);
    const [showPdfViewModal, setShowPdfViewModal] = useState<any>(false);
	const [filePdfName, setPdfFileName] = useState("");
    

    const toolBarRef = useRef<any>(null);

    
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<EqpGroupFormData>({
        defaultValues: {
            address: "",
            name: "",
            type: "",
        },
    });

    type EqpGroupFormData = {
        address: string;
        name: string;
        type: string;
    };

    let tools = [
        {
          name: "Rectangle",
          icons: <BiRectangle className="icon" />,
        },
        {
          name: "Ellipse",
          icons: <IoEllipseOutline className="icon" />,
        },
        {
          name: "Line",
          icons: <FaSlash className="icon" />,
        },
        {
          name: "Cloud",
          icons: <BsCloud className="icon" />,
        },
        {
          name: "Free Hand",
          icons: <BsPencil className="icon" />,
        },
      ];

    const dispatch = useDispatch<any>();
    
    
    useEffect(() => {
        setSelectedSite(loggedInUserData?.userDefaults)
        let body = {
            uniqueSiteId: loggedInUserData?.userDefaults,
          };
        if (loggedInUserData?.userDefaults?.uniqueSiteId) {
            dispatch(getBuildingList(loggedInUserData?.userDefaults?.uniqueSiteId, body))
            setToolData(tools)
        }
        
    }, [])

    useEffect(() => {
        if (newBuilding) {
            let building :any = [];
            buildingLookup == null ? building=[] :building =buildingLookup
            let addedBuilding :any= {id: newBuilding.uniqueBuildingId, name: newBuilding.buildingName}
            building.push(addedBuilding)
            setBuildngLookup(building)
            setSelectedBuilding(addedBuilding);
            setDisabeAddBtn(false);
            dispatch(getDrawingList(selectedSite?.uniqueSiteId, addedBuilding?.id, 1, "", "", ""));
            setShowAddBuildingsModal(false);
            dispatch(setNewBuilding(null));
        }  
        
    }, [newBuilding])

    useEffect(() => {
        if (buildingList) {
            if (buildingList.length > 0) {
                setDisabeAddBtn(false);
                let buildings: any = [];
                buildingList.map((item: any) => {
                    buildings.push({ id: item.uniqueBuildingId, name: item.buildingName })
                })
                setBuildngLookup(buildings)
                setSelectedBuilding(buildings[0]);
                dispatch(getDrawingList(selectedSite?.uniqueSiteId, buildings[0]?.id, 1, "", "", "", setMessage, setDisplayInfoModal));
            } else {
                setMessage("No Buildings exist")
                setDisplayInfoModal(true)
            }
            dispatch(setBuildingList(""))
        }
    }, [buildingList])

    useEffect(() => {
        if (drawingList) {
            setDrawingData(drawingList)
            dispatch(setDrawingList(""));
        }
        if(drawingList && drawingList.length && showMarkupList ){
            setSelectedRows(selectedRows);
            setShowMarkupList(false);
        }
        else if (drawingList && drawingList.length){
            setSelectedRows([drawingList[0]]);
        }
    }, [drawingList])

    useEffect(() => {
        if (drawingData && drawingData.length) setBtnDisable(false);
        else setBtnDisable(true);
    }, [drawingData]);

    useEffect(() => {
        if (selectedRows && selectedRows.length) {
          filterSettingItemList();
        }
      }, [selectedRows])

    const filterSettingItemList = () => {
        const settingList: any = [];
        settingList.push({
            id: 1,
            name: 'Revise',
            onClick: () => { reviseDrawingClick(selectedRows) }
        },{
            id: 2,
            name: 'Replace',
            onClick: () => { replaceDrawingClick(selectedRows) }
        },{
            id: 3,
            name: 'List Revisions',
            onClick: () => { revisionListClick(selectedRows) }
        },{
            id: 4,
            name: 'List Markups',
            onClick: () => { markupListClick(selectedRows) }
        })
        setSettingListItems(settingList)
    }

    const onBuildingChange = (data: any) => {
        setSelectedRows([]);
        setSelectedBuilding(data?.value);
        dispatch(getDrawingList(selectedSite?.uniqueSiteId, data?.value?.id, 1, "", "", "", setMessage, setDisplayInfoModal));
    }

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
        let currentStart;
        setPageIndex(options);
        currentStart = options * drawingPageDetails?.currentLength + 1;
        setCurrentStart(currentStart)
        dispatch(getDrawingList(selectedSite?.uniqueSiteId, selectedBuilding.id, currentStart, "", sortField, sortOrder))
        }
    }

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
            sortDirection = "ASC"
        else
            sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
        dispatch(getDrawingList(selectedSite?.uniqueSiteId, selectedBuilding.id, currentStart, "", event?.sortField, sortDirection))
    }

    const onAddDrawingClick = (data) => {
        dispatch(setProgressFlag(true));
        dispatch(onAddDrawing(data, selectedSite?.uniqueSiteId, selectedBuilding?.id, setMessage, setDisplayInfoModal))
    }

    useEffect(() => {
        if (newDrawing) {
          let newData = JSON.parse(JSON.stringify(newDrawing));
          let draingInfo = JSON.parse(JSON.stringify(drawingData));
          if (newData.length === 0) {
            setMessage("Drawing already exists")
            setDisplayInfoModal(true);
          } else {
          draingInfo.unshift(newData[0]);
          setDrawingData(draingInfo);
          setShowAddAsBuilts(false);
          setMessage("Drawing added");
		  setDisplayInfoModal(true);
          setSelectedRows([newData[0]]);
          }
          dispatch(setNewDrawing(""))
        }
      }, [newDrawing])

      


   


    const editIconClick = () => {
        if (selectedRows && selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setShowEditAsBuilts(true)
        }
    }

    const onEditDrawingClick = (data) => {
        dispatch(onUpdateDrawing(selectedSite?.uniqueSiteId, selectedBuilding?.id, selectedRows[0].drawingId, data, setMessage, setDisplayInfoModal, setSelectedRows, false))
    }

    useEffect(() => {
        if (updatedDrawing) {
          let updatedData = JSON.parse(JSON.stringify(updatedDrawing));
          let drawingInfo = JSON.parse(JSON.stringify(drawingData));
          let currentIndex = drawingInfo.findIndex((item: any) => item.drawingId === updatedData[0].drawingId);
          const newDrawingList: any = drawingInfo.filter((item: any) => item.drawingId !== updatedData[0].drawingId);
          newDrawingList.splice(currentIndex, 0, updatedData[0]);
          setDrawingData(newDrawingList);
          setSelectedRows([updatedData[0]]);
          setShowEditAsBuilts(false);
          dispatch(setUpdatedDrawing(""));
        }
      },[updatedDrawing])

      useEffect(() => {

        if (previousDrawing) {
          let previousDrawingData = JSON.parse(JSON.stringify(previousDrawing));
          let drawingInfo = JSON.parse(JSON.stringify(drawingData));
          let newDrawingList: any;
    
          if(previousDrawing?.revisions == -1)
          {
            newDrawingList = drawingInfo.filter((item: any) => item.drawingId !== previousDrawingData?.drawingId);
            if(newDrawingList?.length >0){
            setSelectedRows([newDrawingList[0]]);}
            else
            {
                setSelectedRows([]);
                newDrawingList = [];
            }
          }
          else
          {
          let  index: any = drawingInfo.findIndex((item: any) => item.drawingId === previousDrawingData?.drawingId);
          newDrawingList = drawingInfo.filter((item: any) => item.drawingId !== previousDrawingData?.drawingId);
          newDrawingList.splice(index, 0, previousDrawingData);
          setSelectedRows([newDrawingList[index]]);
          }
         
          setDrawingData(newDrawingList);
          dispatch(setDeletedMarkupData(""));
          dispatch(setPreviousDrawing(""));

        }
      },[previousDrawing])

      const onRowEditValidation = (rowData: any) => {
        let value = true;
        if (!rowData.description) {
          value = false;
        }
        return value;
      }

      const onDrawingRowEdit = (submitData: any) => {
        dispatch(onUpdateDrawing(selectedSite?.uniqueSiteId, selectedBuilding?.id, submitData.drawingId, submitData, setMessage, setDisplayInfoModal, setSelectedRows, true))
      };

    const deleteIconClick = () => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setDisplayConfirmModal(true);
            setMessage("Are you sure you want to delete the selected Drawing?")
        }
    };

    const viewIconClick = () => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setSelectedDrawing(selectedRows)
            setPdfFileName(selectedRows[0]?.dwgFileName);
            if(selectedRows[0]?.dwgFileName.includes(".dwg")||(selectedRows[0]?.dwgFileName.includes(".DWG"))){
            if(DrawingObj && isDrawingOpen){
                setshowDrawing(false);
                DrawingObj.close(function () {  
                    setshowDrawing(true);
                });
                setRightToggle(false);
                setLeftToggle(true);
                setShowMarkupList(true);
            }
            else
            {                
                setshowDrawing(true);
                setRightToggle(false);
                setLeftToggle(true);
                setShowMarkupList(true);
            }  
         }
         else
        {
            setShowPdfViewModal(true);
        }
              
        }
    };
   
    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
      }, []);

    const onCancel=()=>{
        setDisplayConfirmModal(false);
    }

    const onConfirmAction = () => {
        setDisplayConfirmModal(false);
        dispatch(deleteDrawing(selectedSite?.uniqueSiteId, selectedBuilding?.id, selectedRows[0].drawingId,selectedRows[0].revisions, setSelectedRows, setMessage, setDisplayInfoModal))
    }

    const reviseDrawingClick = (selectedRows) => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setShowReviseAsbuilts(true)
        }
    }

    const onReviseDrawing = (data) => {
        dispatch(setProgressFlag(true));
        dispatch(onDrawingRevise(data, selectedSite?.uniqueSiteId, selectedBuilding?.id, selectedRows[0]?.drawingId, setMessage, setDisplayInfoModal))
    }

    const onAddBuildingData = (data: any) => {
        dispatch(onAddBuilding(data, setMessage, setDisplayInfoModal))
    }

    const onAddBuildingClick = () => {
        setShowAddBuildingsModal(true)
    }

    useEffect(() => {
        if (revisedDrawing) {
            dispatch(getDrawingList(selectedSite?.uniqueSiteId, selectedBuilding.id, 1))
            let revisedData = JSON.parse(JSON.stringify(revisedDrawing));
            let drawingInfo = JSON.parse(JSON.stringify(drawingData));
            let currentIndex = drawingInfo.findIndex((item: any) => item.drawingId === revisedData[0].drawingId);
            const newDrawingList: any = drawingInfo.filter((item: any) => item.drawingId !== selectedRows[0].drawingId);
            newDrawingList.splice(currentIndex, 0, revisedData[0]);
            setDrawingData(newDrawingList);
            setSelectedRows([revisedData[0]]);
            setMessage("Drawing revised");
            setDisplayInfoModal(true);
            setShowReviseAsbuilts(false);
            dispatch(setRevisedDrawing(""));
        }
    }, [revisedDrawing])

    const replaceDrawingClick = (selectedRows) => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setShowReplaceAsbuilts(true);
        }
    }

    const onReplaceDrawing = (data) => {
        dispatch(setProgressFlag(true));
        dispatch(onDrawingReplace(data, selectedSite?.uniqueSiteId, selectedBuilding?.id, selectedRows[0]?.drawingId, setMessage, setDisplayInfoModal))
    }

    useEffect(() => {
        if (replacedDrawing) {
            let replacedData = JSON.parse(JSON.stringify(replacedDrawing));
            let drawingInfo = JSON.parse(JSON.stringify(drawingData));
            let currentIndex = drawingInfo.findIndex((item: any) => item.drawingId === replacedData[0].drawingId);
            const newDrawingList: any = drawingInfo.filter((item: any) => item.drawingId !== replacedData[0].drawingId);
            newDrawingList.splice(currentIndex, 0, replacedData[0]);
            setDrawingData(newDrawingList);
            setSelectedRows([replacedData[0]]);
            setMessage("Drawing replaced");
            setDisplayInfoModal(true);
            setShowReplaceAsbuilts(false);
            dispatch(setReplacedDrawing(""));
        }
    }, [replacedDrawing])

    const revisionListClick = (selectedRows) => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else {
            setShowListRevisions(true)
        }
    }

    const markupListClick = (selectedRows) => {
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a Drawing");
            setDisplayInfoModal(true);
        } else if(selectedRows[0].dwgFileName.includes(".dwg")){
            setShowListMarkups(true)
            setShowMarkupList(true)
        }
        else
        {
            setMessage("No Markups Added");
            setDisplayInfoModal(true);
        }
    }

    const onDownload=()=>{
        dispatch(downloadDrawing(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id,selectedRows[0]?.drawingId, selectedRows[0]?.dwgFileName, setMessage, setDisplayInfoModal))
    }

   
    const onCloseClick = () => {
        setshowDrawing(false);
        setLeftToggle(false);
        setRightToggle(true);
    }
    const onEXpandDrawing = () => {
        setLeftToggle(!leftToggle);
        setRightToggle(false);
        setDrawingresize(!drawingresize);
        setShowMarkupList(true);
        dispatch(getDrawingList(selectedSite?.uniqueSiteId, selectedBuilding.id, currentStart, "", sortField, sortOrder))
    }

    const onEXpandTable = () => {
        setRightToggle(!rightToggle);
        setLeftToggle(false);
        setShowMarkupList(true);
        dispatch(getDrawingList(selectedSite?.uniqueSiteId, selectedBuilding.id, currentStart, "", sortField, sortOrder))
    }
   
  return (
        <div className="asBuild-parent">
            <div className={`asBuild-wrapper ${leftToggle ? "drawing-f" : ""} ${rightToggle ? "table-f" : ""}`}>
                <div className="as-build-drawing__wrapper">
                    <div className="asBuild-drawing">
                        <div
                            className="drawing-expand-btn"
                            onClick={() => {
                                onEXpandDrawing()
                            }}
                            title="Expand Drawing"
                        >
                            {rightToggle===false?(<AiOutlineDoubleRight className="icon right" />):<BiDotsVertical className="icon left" />}
                        </div>
                        <div className="type-list">
                            <div className="item">
                                <h6>Site</h6> <span title={selectedDrawing[0]?.siteName}>{selectedDrawing[0]?.siteName}</span>
                            </div>
                            <div className="item">
                                <h6>Building</h6>
                                <span title={selectedDrawing[0]?.buildingName}>{selectedDrawing[0]?.buildingName}</span>
                            </div>
                            <div className="item">
                                <h6>Floor</h6>
                                <span title={selectedDrawing[0]?.floorName}>{selectedDrawing[0]?.floorName}</span>
                            </div>
                            <div className="item">
                                <h6>Category</h6>
                                <span title={selectedDrawing[0]?.category}>{selectedDrawing[0]?.category}</span>
                            </div>
                            <div className="item">
                                <h6>Revision Number </h6>
                                <span title={selectedDrawing[0]?.revisions}>{selectedDrawing[0]?.revisions}</span>
                            </div>
                            <div className="item">
                                <h6>File Name</h6>
                                <span title={selectedDrawing[0]?.dwgFileName}>{selectedDrawing[0]?.dwgFileName}</span>
                            </div>
                            <div className="item">
                                <h6>Description</h6>
                                <span title={selectedDrawing[0]?.description}>{selectedDrawing[0]?.description}</span>
                            </div>
                        </div>
                     
                             {showDrawing ? <AsbuiltsDrawing
                                    selectedDrawing={selectedDrawing[0]}
                                     bIsResizeDrawing={drawingresize}
                                     selectedBuilding={selectedBuilding}
                                     selectedSite={selectedSite}
                                     closeDrawingView={onCloseClick}
                                     showMarkupTools={true}
                                     currentStart={currentStart}
                                /> : null}
                      
                    </div>
                </div>
                <div className="as-build-table__wrapper">
                    <div
                        className="table-expand-btn"
                        onClick={() => {
                            onEXpandTable()   
                        }}
                        title="Expand Table"
                    >
                        {leftToggle===false?(<AiOutlineDoubleLeft className="icon left" />):<BiDotsVertical className="icon left" />}
                    </div>
                    <div className="as-build-table">
                        <div className="table-header">
                            <span>Drawings</span>
                        </div>
                        <div className="header-second-row">
                            <div className="left-col">
                                <div className="select-box">
                                    <div className="custom-float-field">
                                        <div className="field-name-col">
                                            <h6>
                                                Building <span>*</span>
                                            </h6>
                                        </div>
                                        
                                        <div className=" field">
                                            <span className="p-float-label">
                                                <Dropdown
                                                    inputId="equipment-type"
                                                    className="w-100 error-tooltip"
                                                    value={selectedBuilding}
                                                    options={buildingLookup}
                                                    optionLabel="name"
                                                    onChange={onBuildingChange}
                                                />
                                            </span>
                                        </div>

                                        <div className="field">
                                            <span className="p-float-label action-btns ">
                                                <Button tooltip="Add Building" tooltipOptions={{ position: "top" }} className='button-icon'>
                                                    <BsPlusLg className='icon' onClick={onAddBuildingClick}/>
                                                </Button>
                                            </span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-config">
                                <div className="action-btns">
                                    <Button className="button-icon" tooltip="Add" tooltipOptions={{ position: "top" }} disabled={disableAddBtn}>
                                        <BsPlusLg className="icon" onClick={() => setShowAddAsBuilts(true)}/>
                                    </Button>
                                    <Button className="button-icon" tooltip="Edit" tooltipOptions={{ position: "top" }} disabled={btnDisable}>
                                        <BsFillPencilFill className="icon" onClick={editIconClick}/>
                                    </Button>
                                    <Button className="button-icon" tooltip="Delete" tooltipOptions={{ position: "top" }} disabled={btnDisable}>
                                        <BsTrashFill className="icon" onClick={deleteIconClick}/>
                                    </Button>
                                    <Button className="button-icon" tooltip="View" tooltipOptions={{ position: "top" }} disabled={btnDisable}>
                                        <BsFillEyeFill className="icon" onClick={viewIconClick} />
                                    </Button>
                                    <Button className="button-icon" onClick={(e) => downloadRef.current?.toggle(e)} tooltip="Download" tooltipOptions={{ position: "top" }} hidden={true} disabled={btnDisable}>
                                        <FaDownload className="icon" />
                                        <FaChevronDown className="p-button-text caret-icon" />
                                    </Button> 
                                    <Button className="button-icon" onClick={(e) => settingRef.current?.toggle(e)} tooltip="More" tooltipOptions={{ position: "top" }} disabled={btnDisable}>
                                        <FaCog className="icon" />
                                        <FaChevronDown className="p-button-text caret-icon" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <DataTableComponent
                                rows={drawingData}
                                cols={DRAWING_LIST_COL}
                                paginator={true}
                                dataKeyId="drawingId"
                                title={``}
                                showroweditor
                                pageDetails={drawingPageDetails}
                                pageIndex={pageIndex}
                                onPageChanged={onPageChanged}
                                onSortData={onSort}
                                showCustomSearchCmp
                                getSelectedRowData={handleRowSelect}
                                defaultSelectedRows={selectedRows}
                                onRowEditValidation={onRowEditValidation}
                                onSaveRowEdit={onDrawingRowEdit}
                                settingRef={settingRef}
                                settingListItems={settingListItems}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PopOver ref={downloadRef}>
                <ul>
                    <li onClick={onDownload}>DWG File</li>
                    <li>Export All</li>
                    <li>Export Highlighted</li> 
                </ul>
            </PopOver>
            {showAddAsBuilts? (  <AddAsBuilts
				showModal={showAddAsBuilts}
				setShowModal={setShowAddAsBuilts}
				selectedRows={selectedRows}
                selectedBuilding={selectedBuilding}
                selectedSite={selectedSite}
                onAddDrawing={onAddDrawingClick}
			/>) : null}
            {showEditAsBuilts? (  <EditAsBuilts
				showModal={showEditAsBuilts}
				setShowModal={setShowEditAsBuilts}
				selectedRows={selectedRows}
                selectedBuilding={selectedBuilding}
                selectedSite={selectedSite}
                onEditDrawing={onEditDrawingClick}
			/>) : null}
             {showListRevisions? (  <CommonDialog
                modelName="ListRevisions"
				showModal={showListRevisions}
				setShowModal={setShowListRevisions}
				selectedRowData={selectedRows}
                selectedBuilding={selectedBuilding}
			/>) : null} 
            {showListMarkups? (  <CommonDialog
                modelName="ListMarkups"
				showModal={showListMarkups}
				setShowModal={setShowListMarkups}
				selectedRowData={selectedRows}
                selectedBuilding={selectedBuilding}
			/>) : null} 
            
            
           
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                cancelAction={onCancel}
                message={message}
            />
            {showPdfViewModal &&
				<PdfDrawingViewer
					showModal={showPdfViewModal}
					setShowModal={setShowPdfViewModal}
					fileName={filePdfName}
					setFileName={setPdfFileName}
					drawingId={selectedRows[0]?.drawingId}
                    revisionNumber={selectedRows[0]?.revisions}
				/>
			}
            {showReviseAsbuilts? (  <ReviseASbuilts
				showModal={showReviseAsbuilts}
				setShowModal={setShowReviseAsbuilts}
                selectedRows={selectedRows}
                selectedBuilding={selectedBuilding}
                onReviseDrawing={onReviseDrawing}
			/>) : null}
            {showReplaceAsbuilts? (  <ReplaceASbuilts
				showModal={showReplaceAsbuilts}
				setShowModal={setShowReplaceAsbuilts}
                selectedRows={selectedRows}
                selectedBuilding={selectedBuilding}
                onReplaceDrawing={onReplaceDrawing}
			/>) : null}
         {showAddBuildingsModal && (
          <AddBuildings
            showModal={showAddBuildingsModal}
            setShowModal={setShowAddBuildingsModal}
            selectedSite={selectedSite}
            onAddBuildingClick = {onAddBuildingData}
          />
        )}
        </div>
    );
};

export default AsBuilts;

const disableEditor = (options) => {
    return <InputText type="text" value={options.value} disabled onChange={(e) => options.editorCallback(e.target.value)} />;
}

const textEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
      <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
      {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
}

const getErrorMsg = options => {
    const { field, value } = options;
    const FIELD_REQUIRED = 'This field is required';
    switch (field) {
        case 'description': {
            if (!value?.trim())
                return FIELD_REQUIRED;
            break;
        }
        default: {
            return ''
        }
    }
};

 const DRAWING_LIST_COL = [
	{
		field: "siteName",
		header: "Site",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "buildingName",
		header: "Building",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "floorName",
		header: "Floor",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "category",
		header: "Category",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "type",
		header: "Type",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "description",
		header: "Description",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => textEditor(options),
	},
    {
		field: "dwgFileName",
		header: "File Name",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "dwgFileSize",
		header: "File Size (KB)",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},
    {
		field: "uploadedBy",
		header: "Uploaded By",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "uploadedOn",
		header: "Uploaded On",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "revisions",
		header: "Revisions",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},
    {
		field: "markups",
		header: "Markups",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},

];




