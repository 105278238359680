import React from 'react'
import { Dialog } from 'primereact/dialog';
import { baseURL } from "../../../service/HttpService";

interface DocumentViewerPropType {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    streamData: any;
    setStreamData: (state: any) => void;
    fileName?: string;
    setFileName: (state: any) => void;
    uniqeSystemId?: any;
    systemInspectionReportId?: any;
    reportType?: any;
    setReportType?: (state: any) => void;
}
const DocumentViewer: React.FC<DocumentViewerPropType> = ({
    showModal = false,
    setShowModal = () => { },
    streamData = {},
    setStreamData = () => { },
    fileName = "",
    setFileName = () => { },
    uniqeSystemId = "",
    systemInspectionReportId = "",
    reportType = "",
    setReportType = () => { },
}) => {
    let reportUrl = "";
    if (reportType && reportType === "Draft") {
        reportUrl = `${baseURL}api/v1/inspection/systems/${uniqeSystemId}/reports/${systemInspectionReportId}/draft/view`;
    }
    else if (reportType && reportType === "Final") {
        reportUrl = `${baseURL}api/v1/inspection/systems/${uniqeSystemId}/reports/${systemInspectionReportId}/final/view`;
    }
    return (
        <div>
            <Dialog
                header={<span title={`${fileName}`}>View Report ({fileName})</span>}
                visible={showModal}
                style={{ width: "75vw", height: "85%" }}
                onHide={() => {
                    setShowModal(false);
                    setStreamData('');
                    setFileName('');
                    setReportType('');
                }}
                className="view-file-modal">
                <div className="content-box">
                    <div className='file-content'>
                        {(reportUrl) ?
                            <object data={reportUrl} title={fileName} type="application/pdf" width="100%" height="100%" ></object>
                            :
                            (<></>)
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DocumentViewer;