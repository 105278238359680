import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { zlink, baseURL } from "../../../../service/HttpService"
import { AppThunk } from "../../../store";

export interface UserState {
	errors: any;
	userList: any;
	userRoles: any;
	userPageDetails: any;
	loggedInUser: any;
	existingLogin: any;
	userDetails: any;
	existingEmail: any;
	userSearchId:any;
	userRoleIdOnSearch:any;
	inspectionTask: any;
	userPrivileges: any;
	userFilterRoles:any;
	selectedUserRow: any;
}

let initialState: UserState = {
	errors: "",
	userList: [],
	userRoles: [],
	userPageDetails: [],
	loggedInUser: [],
	existingLogin: "",
	userDetails: "",
	existingEmail: "",
	userSearchId: "",
	userRoleIdOnSearch: "",
	inspectionTask: {},
	userPrivileges: "",
	userFilterRoles:[],
	selectedUserRow: "",
};

const UserSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
		setUserList: (state, { payload }: PayloadAction<any>) => {
			payload.map((element: any) => {
				element.accountExpiryDate = moment(element.accountExpiryDate).format("yyyy-MM-DD").toString()
				element.accountActivationDate = moment(element.accountActivationDate).format("yyyy-MM-DD").toString()
				element.lastLogIn = element.lastLogIn != null ? moment(element.lastLogIn).format("yyyy-MM-DD").toString() : " "
				return null;
			});
			state.userList = payload;
		},
		setUserRoles: (state, { payload }: PayloadAction<any>) => {
			state.userRoles = payload;
		},
		setUserPageDetails: (state, { payload }: PayloadAction<any>) => {
			state.userPageDetails = payload;
		},
		setLoggedInUser: (state, { payload }: PayloadAction<any>) => {
			state.loggedInUser = payload;
		},
		setDeletedUserList(state, { payload }: PayloadAction<any>) {
			payload.map(element => {
				let currentIndex = state.userList.findIndex((item: any) => item.uniqueUserId === element?.uniqueUserId);
				state.userList = state.userList.filter((item: any) => item.uniqueUserId !== element?.uniqueUserId);
				state.userList.splice(currentIndex, 0, element);
			});
		},
		setUserRoleConverted(state, { payload }: PayloadAction<any>) {
			state.userList = state.userList.filter((item: any) => item.uniqueUserId !== payload?.uniqueUserId);
		},
		setLoginAvailable: (state, { payload }: PayloadAction<any>) => {
			state.existingLogin = payload;
		},
		setNewUserData: (state, { payload }: PayloadAction<any>) => {
			payload.accountExpiryDate = moment(payload.accountExpiryDate).format("yyyy-MM-DD").toString()
			payload.accountActivationDate = moment(payload.accountActivationDate).format("yyyy-MM-DD").toString()
			state.userList.unshift(payload);
		},
		setUserDetails: (state, { payload }: PayloadAction<any>) => {
			state.userDetails = payload;
		},
		setUpdatedUserData: (state, { payload }: PayloadAction<any>) => {
			payload.accountExpiryDate = moment(payload.accountExpiryDate).format("yyyy-MM-DD").toString()
			payload.accountActivationDate = moment(payload.accountActivationDate).format("yyyy-MM-DD").toString()
			let currentIndex = state.userList.findIndex((item: any) => item.uniqueUserId === payload?.uniqueUserId);
			state.userList = state.userList.filter((item: any) => item.uniqueUserId !== payload?.uniqueUserId);
			state.userList.splice(currentIndex, 0, payload);
		},
		setEmailAvailable: (state, { payload }: PayloadAction<any>) => {
			state.existingEmail = payload;
		},
		setUserSearchId: (state, { payload }: PayloadAction<any>) => {
			state.userSearchId = payload;
		},
		setUserRoleIdOnSearch: (state, { payload }: PayloadAction<any>) => {
			state.userRoleIdOnSearch = payload;
		},
		setInspectionTask: (state, { payload }: PayloadAction<any>) => {
			state.inspectionTask = payload;
		},
		setRestoredUserData: (state, { payload }: PayloadAction<any>) => {	
			let currentIndex = state.userList.findIndex((item: any) => item.uniqueUserId === payload?.uniqueUserId);
			state.userList = state.userList.filter((item: any) => item.uniqueUserId !== payload?.uniqueUserId);
			state.userList.splice(currentIndex, 0, payload);		
		},
		setUserPrivileges: (state, { payload }: PayloadAction<any>) => {
			state.userPrivileges = payload;
		},
		setUserFilterRoles: (state, { payload }: PayloadAction<any>) => {
			state.userFilterRoles = payload;
		},
		setSelectedUserRow: (state, { payload }: PayloadAction<any>) => {
			state.selectedUserRow = payload;
		},
	},
});

export const getUserList = (body, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = "", searchStatusId: any = 0): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/user/list?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}&statusId=${searchStatusId}`, body);
		if (res.status === "done") {
			dispatch(setUserList(res?.data?.userDetails  ? res?.data?.userDetails : []));
			dispatch(setUserPageDetails(res?.data?.pageDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getUserSearchList = (currentStart, uniqueUserId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users?keyword=null&selectedUserId=${uniqueUserId}`);
		if (res.status === "done") {
			dispatch(setUserList(res?.data?.userDetails));
			dispatch(setUserSearchId(""));
			dispatch(setUserRoleIdOnSearch(""));
			dispatch(setUserPageDetails(res?.data?.pageDetails))
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getUserRoles = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/user/userroles`);
		if (res.status === "done") {
			dispatch(setUserRoles(res?.data?.userRoles));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getLoggedInUser = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/user/currentloggedin`);
		if (res.status === "done") {
			dispatch(setLoggedInUser(res?.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const checkLoginNameAvailable = (loginName, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/user/checkuseravailability`, loginName);
		if (res.status === "done") {
			dispatch(setLoginAvailable(res.data));
		}
		else if (res.message === "User details not found") {
			setMessage("Login name available");
			setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const deleteUser = (userIds, setMessage, setDisplayInfoModal, selectedUser): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/user/remove`, userIds);
		if (res.status === "done") {
			userIds.forEach(element => {
				let findIndex = selectedUser.findIndex((item: any) => item.uniqueUserId === element);
				if (findIndex >= 0) {
					selectedUser[findIndex] = { ...selectedUser[findIndex], status: "Deleted" };
				}
			});
			setMessage("User(s) deleted");
			setDisplayInfoModal(true);
			dispatch(setDeletedUserList(selectedUser));
			dispatch(setSelectedUserRow(selectedUser));
		} else {
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const onAddUser =
	(body: any, setMessage, setDisplayInfoModal, setShowAddModal, setSelectedRows): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/user/add`, body);
				if (res.status === "done") {
					dispatch(setNewUserData(res.data));
					setSelectedRows([res.data])
					setMessage("User added and the password has been sent to the email address"); setShowAddModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const getUserDetails = (uniqueUserId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/user/${uniqueUserId}`);
		if (res.status === "done") {
			dispatch(setUserDetails(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onEditUser =
	(body: any, uniqueUserId, setMessage, setDisplayInfoModal, setShowEditModal , selectedRows): AppThunk =>
		async (dispatch) => {
			try {				
				const res = await zlink.post(`${baseURL}api/v1/admin/user/${uniqueUserId}/update`, body);
				if (res.status === "done") {
					res.data={...res.data, lastLogIn:selectedRows.lastLogIn};
					dispatch(setUpdatedUserData(res.data));
					dispatch(setSelectedUserRow([res.data]));
					setMessage("User updated");
					setShowEditModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const checkEmailAvailable = (email): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/common/user/checkemailinuse`, email);
		if (res.status === "done") {
			dispatch(setEmailAvailable(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onSearchUsers = (body: any, setMessage, setDisplayInfoModal, setShowModal, setUserSearchAppliedFlag): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/users/search`, body);
		if (res.status === "done") {
			if (res?.data?.userDetails) {
				dispatch(setUserList(res?.data?.userDetails));
				dispatch(setUserPageDetails(res?.data?.pageDetails));
				setShowModal(false);
				setUserSearchAppliedFlag(false);
			} else {
				setShowModal(false);
				setMessage("No Users exist");
				setDisplayInfoModal(true);
				dispatch(setUserList([]));
				setUserSearchAppliedFlag(false);
			}
		}
	} catch (error) {
		dispatch(setErrors(error));
		setUserSearchAppliedFlag(false);
	}
};

export const getInspectionTask = (uniqueUserId, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users/${uniqueUserId}/inspectiontask`);
		if (res.status === "done") {
			dispatch(setInspectionTask(res.data));
		} else {
			dispatch(setErrors(res.error));
			setMessage("Selected User is not assigned to any active Inspection Task");
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const quitTask = (formData:any, setMessage, setDisplayInfoModal, setShowQuitTaskModal ): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users/${formData.uniqueUserId}/inspectiontask/${formData.inspectionTaskId}/quit`);
		if (res.status === "done") {
			dispatch(setInspectionTask({}));
			setShowQuitTaskModal(false);
			setMessage("Selected User is quit from the Inspection Task");
			setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
			if (res.message) {
				setMessage(res.message);
          		setDisplayInfoModal(true);
			}
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSalesOfficesOfUser = (uniqueUserId, setSelectedSalesOffices) : AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users/${uniqueUserId}/salesoffices`);
		if (res.status === "done") {
			if (res.data) {
				if (res.data.salesOfficeDetails) {
					setSelectedSalesOffices(res.data.salesOfficeDetails);
				}
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
}

export const convertUserRole = (selectedUser:any, body:any, setMessage, setDisplayInfoModal, setShowConvertUserModal): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/users/${selectedUser.uniqueUserId}/role`, body);
		if (res.status === "done") {
			dispatch(setUserRoleConverted(selectedUser));
			setShowConvertUserModal(false);
			setMessage(`Selected User ${selectedUser.userName} is converted to ${selectedUser.newRole} User Role`);
			setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
			if (res.message) {
				setMessage(res.message);
          		setDisplayInfoModal(true);
			}
		}		
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const restoreSelectedUser = (uniqueUserId, setMessage, setDisplayInfoModal, selectedUser): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/users/${uniqueUserId}/restore`, null)
		if (res.status === "done") {
				selectedUser = {...selectedUser, status: "Active" };
				let selectedData: string[] = [];
				selectedData.push(selectedUser);
			setMessage(`User Restored`);
			dispatch(setRestoredUserData(selectedUser))
			dispatch(setSelectedUserRow(selectedData));
			setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const resetSelectedUser = (uniqueUserId, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/users/${uniqueUserId}/resetpassword`, null)
		if (res.status === "done") {
			setMessage(`Password has been reset and sent to the email address provided`);
			setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onGetUserPrivileges = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/accountdetail`)
		if (res.status === "done") {
			if (res.data) {
				if (res.data.privileges && res.data.privileges.length) {
					dispatch(setUserPrivileges(res.data.privileges));
				}
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const { setErrors,
	setUserList,
	setUserRoles,
	setUserFilterRoles,
	setUserPageDetails,
	setLoggedInUser,
	setDeletedUserList,
	setLoginAvailable,
	setNewUserData,
	setUserDetails,
	setUpdatedUserData,
	setEmailAvailable,
	setUserSearchId,
	setUserRoleIdOnSearch,
	setInspectionTask,
	setUserRoleConverted,
	setRestoredUserData,
	setUserPrivileges,
	setSelectedUserRow,
} = UserSlice.actions;
export default UserSlice.reducer;
